import _ from 'lodash'
import moment from 'moment'
import config from '../../config'
import {
  ConcreteLevel,
  ConcreteResistanceLevel,
  ConcreteVoltageLevel,
  Device,
  Log,
  State,
} from '../../types'
import { getLoginUserDeviceIds } from '../Auth/selectors'
import { getTimestamp } from '../Clock/selectors'
import { getUser } from '../UserById/selectors'

export const getDevice = (state: State, deviceId: string) =>
  state.DeviceById[deviceId]
export const getDeviceIds = (state: State) => _.keys(state.DeviceById)

// ConcreteMonitor

export const getVisibleConcreteMonitorDeviceIds = (
  state: State,
  visible: boolean = true,
) => {
  const devices = getConcreteMonitorDevices(state)
  const list = _.sortBy(
    visible ? devices.filter(v => v.info.visible) : devices,
    [v => v.info.priority, v => v.info.label],
  )

  return list.map(v => v.id)
}

export const getConcreteMonitorDevices = (state: State) => {
  const deviceIds = getLoginUserDeviceIds(state)

  return _.filter(
    deviceIds.map(did => getConcreteMonitorDevice(state, did)),
    v => !!v,
  ) as Device[]
}

export const getCurrentUser = (state: State, deviceId: string) => {
  const device = getDevice(state, deviceId)

  return getUser(state, device.currentUserId)
}

export const getConcreteMonitorDevice = (state: State, deviceId: string) => {
  const device = getDevice(state, deviceId)

  if (!device) {
    return undefined
  }
  const now = getTimestamp(state)

  return convertConcreteMonitorDevice(device, now)
}

const levelExpand = (
  level: ConcreteLevel,
): {
  resistanceLevel: ConcreteResistanceLevel
  voltageLevel: ConcreteVoltageLevel
} => ({
  resistanceLevel: ['X', 'D', 'C', 'B', 'A', 'A', 'A'][
    level
  ] as ConcreteResistanceLevel,
  voltageLevel: ['X', 'D', 'A', 'A', 'A', 'B', 'C'][
    level
  ] as ConcreteVoltageLevel,
})

export const convertConcreteMonitorLog = (log: any): Log => {
  const sensors = _.map(_.range(1, 12), id => {
    const sensor = log.fields.sensors[id]
    const level: ConcreteLevel = (sensor && sensor.level) || 0

    return {
      ...sensor,
      level,
      ...levelExpand(level),
    }
  })

  return {
    ...log,
    fields: {
      sensors,
    },
  }
}

const convertConcreteMonitorDevice = (device: any, now: number): Device => {
  const info = device.info || {}

  return {
    ...device,
    lastLogs: _.mapValues(device.lastLogs, convertConcreteMonitorLog),
    info: {
      priority: info.priority || 0,
      label: info.label || '',
      visible: info.visible || false,
      threasholds: info.threasholds,
      memo: info.memo || '',
      token: info.token || '',
      ...convertKeep(device.updatedAt, device.keepedAt, now),
      status: info.status || undefined,
    },
  }
}

function convertKeep(
  timestamp: undefined | number,
  keepTime: undefined | number,
  now: number,
): {
  keepedStr: string
  keeped: boolean
  // keepedNewSession: boolean
  keepExpired: boolean
} {
  if (!timestamp) {
    return {
      keepedStr: 'データなし',
      // keepedNewSession: false,
      keeped: false,
      keepExpired: false,
    }
  }
  // console.log({ keepTime })
  if (!keepTime) {
    keepTime = timestamp
  }
  const ts = moment(timestamp)
  const kts = moment(keepTime)
  const keeped = kts.isAfter(moment(now).subtract(1, 'minute'))
  const keepExpired = ts.isBefore(
    moment(now).subtract(config.keepExpiredMinutes, 'minute'),
  )

  // console.log({ keeped, keepTime, keepedNewSession, keepExpired })

  return {
    keepedStr: `${keeped ? '接続あり' : '接続なし'}`,
    // keepedStr: `${keeped ? '記録中' : '記録停止中'} (${ts.fromNow()})`,
    keeped,
    // keepedNewSession: keepExpired,
    keepExpired,
  }
}
