import { Button, Modal } from '@material-ui/core'
import React, { useEffect } from 'react'
import { ModalCardSmall } from '../../components'
import GraphConfigForm from './GraphConfigForm'
import { useGraphConfig, useGraphConfigModal } from './useViewConfig'

function GraphConfigModal() {
  const [modalOpen, setModalOpen] = useGraphConfigModal()
  const [graphConfig, setGraphConfig] = useGraphConfig()

  useEffect(() => {
    if (!modalOpen) return

    setGraphConfig({ ...graphConfig, end: +new Date() })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalOpen])
  const handleClose = () => setModalOpen(false)

  return (
    <Modal open={modalOpen} onClose={handleClose}>
      <ModalCardSmall>
        <GraphConfigForm />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={handleClose}>閉じる</Button>
        </div>
      </ModalCardSmall>
    </Modal>
  )
}

export default GraphConfigModal
