import { Button } from '@material-ui/core'
import React from 'react'
import styled from 'styled-components'

import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import { Admin, Auth, User } from '../../../types'

export type Props = {
  user: User | Admin
  auth: Auth
  title?: string
  onClickTitle: () => void
  onClickLogout: (nextPath?: string) => void
}
const Wrap = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas: 'title name logout';
  grid-template-columns: 1fr max-content max-content;
  @media screen and (max-width: 600px) {
    grid-template-areas: 'title logout' 'name name';
    .username {
      font-size: 0.9rem;
    }
  }
`

const NavBar = (props: Props) => {
  return (
    <div>
      <AppBar position="static" color="secondary">
        <Toolbar>
          <Wrap>
            <Typography
              onClick={props.onClickTitle}
              variant="h6"
              color="inherit"
              style={{ flex: 1, cursor: 'pointer', gridArea: 'title' }}
            >
              {props.title}
            </Typography>
            <Button
              variant="text"
              onClick={() => props.onClickLogout('/login')}
              style={{ marginTop: 0, gridArea: 'logout', textAlign: 'right' }}
              color="inherit"
            >
              ログアウト
            </Button>
            <Typography
              variant="h6"
              color="inherit"
              className="username"
              style={{ gridArea: 'name', textAlign: 'right' }}
            >
              ユーザID: {props.user.nid}　{props.user.label} 様
            </Typography>
          </Wrap>
        </Toolbar>
      </AppBar>
    </div>
  )
}

NavBar.defaultProps = { title: 'ジュウテンミエルカ' }

export default NavBar
