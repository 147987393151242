import { noop } from 'lodash'
import React, { createContext, useContext, useEffect } from 'react'
import { useSessionStorage } from 'react-use'

type ViewMode = 'table' | 'graph'
const viewModes: ViewMode[] = ['table', 'graph']
const next = <T,>(arr: T[], cur: T) =>
  arr[(arr.findIndex(e => e === cur) + 1) % arr.length]

const nextMode = (mode: ViewMode) => next(viewModes, mode)

type GraphConfig = {
  view: 'stre' | 'temp'
  up: number
  down: number
  start: number
  end: number
  hideCh: Record<string, boolean>
}

type Config = {
  viewMode: ViewMode
  graph: GraphConfig
  graphConfigOpen: boolean
}

const defaultConfig: Config = {
  viewMode: 'table',
  graph: {
    view: 'stre',
    up: 20,
    down: 0,
    start: +new Date() - 6 * 60 * 60 * 1000,
    end: +new Date(),
    hideCh: {},
  },
  graphConfigOpen: false,
} as const

const ProfileContext = createContext<
  [Config, (config: Config) => void] | undefined
>(undefined)

export const ConfigProvider = ({ children }) => {
  const [config, setConfig] = useSessionStorage<Config>(
    'm-z-config',
    defaultConfig,
  )

  useEffect(() => {
    if (!config.graph || !config.graphConfigOpen === undefined) {
      setConfig({ ...defaultConfig, ...config })
    }
  }, [config, setConfig])

  return (
    <ProfileContext.Provider value={[config, setConfig]}>
      {children}
    </ProfileContext.Provider>
  )
}

export const useConfig = () => useContext(ProfileContext)
export const useViewMode = () => {
  const [config, setConfig] = useConfig() || ([defaultConfig, noop] as const)

  return [
    config.viewMode,
    () => setConfig({ ...config, viewMode: nextMode(config.viewMode) }),
    (viewMode: ViewMode) => setConfig({ ...config, viewMode }),
  ] as const
}

export const useGraphConfig = () => {
  const [config, setConfig] = useConfig() || ([defaultConfig, noop] as const)

  return [
    config.graph,
    (graph: GraphConfig) => setConfig({ ...config, graph }),
  ] as const
}

export const useGraphConfigModal = () => {
  const [config, setConfig] = useConfig() || ([defaultConfig, noop] as const)

  return [
    config.graphConfigOpen,
    (graphConfigOpen: boolean) => setConfig({ ...config, graphConfigOpen }),
  ] as const
}
