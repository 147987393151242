import { actionCreatorFactory } from 'typescript-fsa'
import { SystemCache } from '../../types'

const actionCreator = actionCreatorFactory()

export const updateSystemCache = actionCreator<Partial<SystemCache>>(
  'updateSystemCache',
)

export const updateSystemCacheToggles = actionCreator<{
  [key: string]: boolean
}>('updateSystemCacheToggles')

export const editDeviceStart = actionCreator<string>('editDeviceStart')
export const editDeviceEnd = actionCreator('editDeviceEnd')
export const editUserStart = actionCreator<string>('editUserStart')
export const editUserEnd = actionCreator('editUserEnd')

export const createDeviceStart = actionCreator('createDeviceStart')
export const createDeviceEnd = actionCreator('createDeviceEnd')
export const createUserStart = actionCreator('createUserStart')
export const createUserEnd = actionCreator('createUserEnd')

export const clearSystemCache = actionCreator('clearSystemCache')
export const toggleSystemCache = actionCreator<string>('toggleSystemCache')

export const setShowName = actionCreator<boolean>('setShowName')
