import _ from 'lodash'
import React from 'react'

import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import { ConcreteSensor } from '../../../types'
import LevelBox, {
  ColBox,
  ColTitleBox,
  Content,
  LevelBoxLabel,
} from './LevelBox'

type Props = {
  sensors: { [i: number]: ConcreteSensor }
  completeTimeVisible?: boolean
}

const RegistanceTable = (props: Props) => {
  return (
    <Content>
      <ColTitleBox>
        <LevelBoxLabel>
          <Typography align="center" color="inherit">
            コンクリート
          </Typography>
        </LevelBoxLabel>
        <LevelBoxLabel>
          <Typography align="center" color="inherit">
            水
          </Typography>
        </LevelBoxLabel>
        <LevelBoxLabel>
          <Typography align="center" color="inherit">
            空気
          </Typography>
        </LevelBoxLabel>
        <div></div>
      </ColTitleBox>
      <div style={{ display: 'flex', flex: 1, height: '100%' }}>
        {_.map(props.sensors, (sensor, i) => (
          <ColBox key={i}>
            <RegistanceLevelBox num={i} sensor={sensor} />
          </ColBox>
        ))}
      </div>
    </Content>
  )
}

const NumBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px white;
  margin: 4px 2px 2px;
`

const RegistanceLevelBox = ({
  sensor,
  num,
}: {
  sensor: ConcreteSensor
  num: string
}) => (
  <div
    style={{
      height: '100%',
      display: 'grid',
      gridTemplateRows: '80% 20%',
    }}
  >
    <div style={{ height: '100%', display: 'grid' }}>
      <LevelBox data-level={sensor.level} />
      <LevelBox data-level={sensor.level} />
      <LevelBox data-level={sensor.level} />
    </div>
    <NumBox>
      <Typography align="center" color="inherit">
        {Number(num) + 1}
      </Typography>
    </NumBox>
  </div>
)

export default RegistanceTable
