import { Button, TextField, Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import { FormikProps, useFormik } from 'formik'
import _ from 'lodash'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getLoginUser } from '../../../state/Auth/selectors'
import {
  LinkageFields,
  stopIntegration,
  updateLinkage,
} from '../../../state/Firebase/operations'
import { IntegrationLogs } from '../../../types'
import { IntegrationStatus } from '..'

type Props = {}

export type Fields = LinkageFields

function ConcreteTopicForm(props: Props) {
  const user = useSelector(getLoginUser)
  const current = {
    url: user.integration?.url || '',
    headersText: user.integration?.headersText || '',
  }

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldTouched,
  }: // isValid,
  FormikProps<Fields> = useFormik({
    initialValues: current,
    validate: values => {
      const errors: Partial<Fields> = {}

      if (!values.url) {
        errors.url = 'Required'
      }
      return errors
    },
    onSubmit: (values: Fields, { setSubmitting, setErrors }) =>
      updateLinkage(user, values),
  })

  const change = (title, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(title, true, false)
  }

  const headers = values.headersText
    .split('\n')
    .filter(v => v.split(':').length === 2)
    .map(line => line.split(':').map(v => v.trim()))

  // const formChanged =
  //   values.url !== current.url || values.headersText !== current.headersText
  const { integration } = user

  return (
    <form onSubmit={handleSubmit}>
      <div
        style={{
          display: 'grid',
        }}
      >
        <IntegrationStatus integration={integration} />
        <TextField
          disabled
          label="トークン"
          value={user.tokenBase64}
          fullWidth
        />
        <TextField
          required
          name="url"
          helperText={touched.url ? errors.url : ''}
          error={touched.url && Boolean(errors.url)}
          label="リクエスト先URL"
          value={values.url}
          onChange={change.bind(null, 'name')}
          fullWidth
        />
        <TextField
          name="headersText"
          multiline
          helperText={touched.headersText ? errors.headersText : ''}
          error={touched.headersText && Boolean(errors.headersText)}
          label="HTTPヘッダー(key,valueをコロン区切り、改行毎に一つ設定できます。)"
          value={values.headersText}
          onChange={change.bind(null, 'headersText')}
          fullWidth
        />
        {headers.length > 0 && (
          <div>
            <Typography variant="subtitle1">
              ヘッダーパースプレビュー
            </Typography>

            <PreviewTable>
              <Typography>key</Typography>
              <Typography>value</Typography>
              {_.flatten(headers).map((header, i) => (
                <Typography key={`${i}_0`}>{header}</Typography>
              ))}
            </PreviewTable>
          </div>
        )}
        <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr' }}>
          <Button
            color="secondary"
            disabled={!(integration && integration.mode === 'production')}
            onClick={e => stopIntegration(user)}
          >
            切断する
          </Button>
          <Button
            color="primary"
            disabled={integration && integration.mode !== 'off'}
            onClick={e => handleSubmit()}
          >
            接続する
          </Button>
        </div>
        {integration?.mode === 'test' && (
          <div>
            <CircularProgress />
            <Typography>テスト送信中</Typography>
          </div>
        )}
        <div>
          <Typography variant="h6">直近のログ</Typography>
          <LogPreview>
            {logText(integration?.logs || {}).map((text, i) => (
              <pre key={i}>{text}</pre>
            ))}
          </LogPreview>
        </div>
      </div>
    </form>
  )
}

export function logText(logs: IntegrationLogs): string[] {
  const lines = _.map(
    logs,
    (log, time) =>
      `[${new Date(Number(time)).toLocaleString('ja-JP')}] ${log.status ||
        '---'} ${log.message}<${log.details || ''}>`,
  )

  lines.sort(() => -1)

  return lines.slice(0, 5)
}

const PreviewTable = styled.div`
  display: grid;
  grid-template-columns: max-content max-content;
  p {
    padding: 4px 1rem !important;
    &:nth-child(-n + 2) {
      border-bottom: solid 1px #ccc;
      text-decoration: underline;
    }

    border-left: solid 1px #ccc;
    padding: 4px;
    &:nth-child(even) {
      border-right: solid 1px #ccc;
    }
    &:nth-child(odd) {
      text-transform: lowercase;
    }
  }
`

const LogPreview = styled.pre`
  border: solid 1px gray;
  pre {
    white-space: pre-wrap;
    overflow-x: hidden;
    font-size: 16px;
    margin: 4px;
  }
`

export default ConcreteTopicForm
