import { ThunkAction } from '../../types'
import { toggleSystemCache, updateSystemCacheToggles } from './actions'

export const toggleSetDaySelector = (
  deviceId: string,
  val: boolean,
): ThunkAction => toggleSet(`dayselector_${deviceId}`, val)

export const toggleShowItgLog = () => toggleSystemCache(`configItgLog`)
export const toggleShowSurfaceTemp = () =>
  toggleSystemCache(`configSurfaceTemp`)
export const setShowSurfaceTemp = (v: boolean) =>
  toggleSet('configSurfaceTemp', v)

export const toggleSet = (key: string, val: boolean): ThunkAction => {
  return (dispatch, getState) => {
    dispatch(updateSystemCacheToggles({ [key]: val }))
  }
}
