import * as React from 'react'
import styled from 'styled-components'
import theme from '../theme'

export const CardContainer = styled.div`
  border: solid #ddd 1px;
  border-radius: 5px;
  /* min-width: 100px; */
  width: 100%;
  padding: 5px;
  margin-left: 5px;
`

const modalWidth = '90vw'

export const ModalCard = styled.div`
  background: white;
  width: ${modalWidth};
  height: 90vh !important;
  position: absolute;
  left: calc((100vw - ${modalWidth}) / 2);
  top: 5vh;
  outline: none;
  overflow: scroll;
  padding: 12px;
  ${theme.breakpoints.down('xs')} {
    width: 100%;
    height: 96vh !important;
    top: 2vh;
    left: 0;
  }
  padding-bottom: 120px;
`

export const ModalCardSmall = styled(ModalCard)`
  max-width: 600px;
  @media screen and (min-width: 600px) {
    left: calc((100% - 600px) / 2);
  }
`

const PageWrapper = styled.div`
  padding: 12px;
`

export const Page: React.FC = props => (
  <PageWrapper>{props.children}</PageWrapper>
)

export const ButtonGrid = styled.div`
  display: grid;
  gap: 8px;
  justify-content: flex-end;
  grid-template-columns: max-content max-content max-content;
`

export const ButtonGridCol = styled.div`
  display: grid;
  grid-template-columns: max-content;
`

export const SmallButton = styled.button`
  margin-right: 0.5rem;
  position: relative;
  top: -4px;
`
