import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getTimestamp } from '../../state/Clock/selectors'
import { State } from '../../types'
import { timeLabel } from '../../utils'

type Props = {
  timestamp: number
}
function LastTimeLabel({ timestamp }: Props) {
  const now = useSelector<State, number>(getTimestamp)
  const [timeStr, setTimeStr] = useState<string>('')

  useEffect(() => {
    setTimeStr(timeLabel(timestamp))
  }, [timestamp, now])

  return <>{timeStr}</>
}
export default LastTimeLabel
