import _ from 'lodash'
import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { Device } from '../../types'
import * as actions from './actions'

export type State = { [id: string]: Device }

const initialState: State = {}

export default reducerWithInitialState<State>(initialState)
  .case(actions.receiveDevice, (state, obj) => {
    if (!obj.id) {
      return { ...state }
    }
    return _.merge({}, state, { [obj.id]: obj })
  })
  .case(actions.receiveDeviceToken, (state, { id, token }) => {
    return _.merge({}, state, { [id]: { info: { token } } })
  })
  .case(actions.updateDevice, (state, obj) => {
    if (!obj.id) {
      return state
    }
    return _.merge({}, state, { [obj.id]: obj })
  })
  .case(actions.removeDevice, (state, { id }) => {
    return _.omit(state, [id])
  })
  .case(actions.cleanDevice, () => initialState)
