import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'
import 'firebase/messaging'
import 'firebase/storage'
import _ from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import config from '../../config'
import { historyPush, moveTopPage } from '../../containers/App/operations'
import { initializeFirebase } from '../../services/firebase'
import { apiClient, sleep, toLog } from '../../utils'
import { decrypt, encrypt } from '../../utils/encrypt'
import * as authActions from '../Auth/actions'
import { saveLogin } from '../Auth/operations'
import { getLoginUser, getLoginUserDeviceIds } from '../Auth/selectors'
// import * as actions from './actions'
import { saveDevice } from '../DeviceById/operations'
import { getConcreteMonitorDevice } from '../DeviceById/selectors'
import { setLoginErrorMessage } from '../LoginForm/actions'
import { editUserEnd } from '../SystemCache/actions'
import {
  Cache,
  Device,
  DeviceRaw,
  Integration,
  Log,
  LogRaw,
  Session,
  SessionRaw,
  State,
  ThunkAction,
  User,
} from './../../types/index'
import {
  sessionKeyList,
  sessionKeyStart,
  useSessions,
  useSessionTimes,
} from './../../utils/sessionHook'
import { cleanAdmin } from './../AdminById/actions'
import { cleanDevice, updateDevice } from './../DeviceById/actions'
import { cleanUser, receiveUser } from './../UserById/actions'

const messageLoginError = setLoginErrorMessage(
  '認証できませんでした。ご登録いただいているメールアドレスとパスワードを入力してください。',
)

initializeFirebase()
const fdb = firebase.database()

async function convertLogin(
  email: string,
  password: string,
): Promise<string | undefined> {
  const res = await apiClient
    .post<{ password: string }>('convert_login', {
      email,
      password,
    })
    .catch(e => {
      return undefined
    })

  return res && res.data.password
}

export function passwordLogin(email: string, password: string) {
  return async dispatch => {
    const proPassword = await convertLogin(email, encrypt(password))

    if (!proPassword) {
      dispatch(messageLoginError)
      return
    }
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, decrypt(proPassword))
      .catch(e => {
        if (e.code === 'auth/wrong-password') {
          dispatch(messageLoginError)
        }
        return { user: null }
      })
  }
}

export function logout(nextPath?: string): ThunkAction {
  return async dispatch => {
    await firebase
      .auth()
      .signOut()
      .catch(console.error)
    await dispatch(authActions.logout())
    await dispatch(cleanUser())
    await dispatch(cleanDevice())
    await dispatch(cleanAdmin())
    sessionStorage.removeItem(sessionKeyList)
    sessionStorage.removeItem(sessionKeyStart)
    historyPush(nextPath || '/login')
  }
}

function authStateChanged(uid: string | undefined, redirect: boolean) {
  return async dispatch => {
    if (!uid) {
      return dispatch(authActions.loginFailed())
    }
    const userRef = fdb.ref(`user/${uid}`)
    const snapshot = await userRef.once('value')

    if (snapshot.exists()) {
      const user = snapshot.val()

      await dispatch(saveLogin(user))
      if (redirect) {
        moveTopPage()
      }
      return
    }
    dispatch(messageLoginError)
    dispatch(authActions.loginFailed())
  }
}

export function refInit(): ThunkAction {
  return async dispatch => {
    firebase.auth().onAuthStateChanged(user => {
      dispatch(authStateChanged(user?.uid, false))
    })
  }
}

const userSnapshotChanged = snapshot => dispatch => {
  if (!snapshot) {
    return
  }
  const user = snapshot.val()

  if (!user) {
    return
  }
  dispatch(receiveUser(user))
}

export function syncUserData(): ThunkAction {
  return async dispatch => {
    fdb
      .ref('user')
      .orderByChild('deleted')
      .equalTo(false)
      .on('value', snapshot => {
        if (!snapshot) {
          return
        }
        const users = snapshot.val()

        _.map(users, user => {
          dispatch(receiveUser(user))
        })
      })
  }
}

export function syncLoginUserData(): ThunkAction {
  return async (dispatch, getState) => {
    const userState = getLoginUser(getState())

    fdb.ref(`user/${userState.id}`).on('value', snapshot => {
      dispatch(userSnapshotChanged(snapshot))
    })
  }
}

function loadDevice(deviceId: string): ThunkAction {
  return async dispatch => {
    const { deviceRef, sessionRef } = await getSessionRefs(deviceId)
    const deviceLastLogRef = sessionRef.child('lastLog')

    const device = (await deviceRef.once('value')).val() as DeviceRaw

    if (device.category !== 'zyuutenmieruka') return

    dispatch(
      updateDevice({
        id: deviceId,
        data: device.data,
        info: device.info,
        currentSessionId: device.currentSessionId,
        selector: `${deviceId}__${device.currentSessionId}`,
        updatedAt: device.updatedAt,
        keepedAt: device.keepedAt,
      }),
    )

    fdb.ref(`device/${deviceId}/currentSessionId`).on('value', snap => {
      if (snap.val() !== device.currentSessionId) {
        dispatch(loadDevice(deviceId))
      }
    })

    deviceRef.on('value', snapshot => {
      if (!snapshot) {
        return
      }
      const device = snapshot.val() as Device

      dispatch(
        updateDevice({
          id: deviceId,
          data: device.data,
          info: device.info,
          currentSessionId: device.currentSessionId,
          selector: `${deviceId}__${device.currentSessionId}`,
          updatedAt: device.updatedAt,
          keepedAt: device.keepedAt,
        }),
      )
    })

    deviceLastLogRef.on('value', async (snapshot, id) => {
      const device = (await deviceRef.once('value')).val()

      if (!snapshot) return

      const logRaw: LogRaw | undefined = snapshot.val()

      if (!logRaw) return

      if (config.isDev) console.log('receive firebase', logRaw)

      const log = toLog(logRaw)

      dispatch(
        saveDevice({
          ..._.pick(device, [
            'data',
            'info',
            'updatedAt',
            'currentUserId',
            'currentSessionId',
          ]),
          keepedAt: device.keepedAt || 0,
          selector: `${deviceId}__${device.currentSessionId}`,
          id: deviceId,
          lastLogs: { [device.currentSessionId]: log },
        }),
      )
    })
  }
}

export function loadDevices(): ThunkAction {
  return async (dispatch, getState) => {
    dispatch(syncLoginUserData())
    const deviceIds = getLoginUserDeviceIds(getState())

    deviceIds.forEach(id => dispatch(loadDevice(id)))
  }
}

export async function getSessionRefs(deviceId: string) {
  const deviceRef = fdb.ref(`device/${deviceId}`)
  const deviceRaw: DeviceRaw = (await deviceRef.once('value')).val()
  const { currentUserId, currentSessionId } = deviceRaw
  const sessionPath = `user-device-session-log/${currentUserId}/${deviceId}/${currentSessionId}`
  const sessionRef = fdb.ref(sessionPath)

  return { deviceRef, deviceRaw, sessionRef }
}

const dayEndsUnix = (day: string) => {
  const m = moment(day, 'YYYY-MM-DD')
  const dayStart = m.startOf('day').unix() * 1000
  const dayEnd = m.endOf('day').unix() * 1000

  return { dayStart, dayEnd }
}

export async function loadLogs(deviceId: string, day: string): Promise<Log[]> {
  const { dayStart, dayEnd } = dayEndsUnix(day)
  const { sessionRef } = await getSessionRefs(deviceId)

  const deviceLogRef = sessionRef.child('logs')

  // sessionRef.child('info').on('value', () => {})
  const logsSnap = await deviceLogRef
    .orderByChild('timestamp')
    .startAt(dayStart)
    .endAt(dayEnd)
    .once('value')

  const logRaws: { [key: string]: LogRaw } = logsSnap.val()
  const logs: Log[] = _.map(logRaws, (log, key) => toLog(log, key))

  return logs
}

export function updateUserTitle(userId: string, title: string) {
  return () => {
    fdb.ref(`user/${userId}/title`).set(title)
  }
}

export async function updateDeviceCurrentUser(
  deviceId: string,
  newCurrentUserId: string,
) {
  const deviceRef = fdb.ref(`device/${deviceId}`)
  const device = (await deviceRef.once('value')).val()

  if (newCurrentUserId === device.currentUserId) {
    return
  }
  await fdb.ref(`user/${device.currentUserId}/deviceIds/${deviceId}`).set(null)
  await fdb.ref(`user/${newCurrentUserId}/deviceIds/${deviceId}`).set(true)
  await deviceRef.update({
    currentUserId: newCurrentUserId,
    currentSessionId: Number(device.currentSessionId) + 1,
  })
}

export function updateDeviceInfo(
  deviceId: string,
  fields: { [key: string]: any },
) {
  return () => {
    fdb
      .ref(`device/${deviceId}/info`)
      .update(
        _.pick(fields, ['status', 'priority', 'label', 'visible', 'memo']),
      )
    if (fields.currentUserId) {
      updateDeviceCurrentUser(deviceId, fields.currentUserId)
    }
  }
}

export function updateUser(
  id: string,
  fields: { label: string; memo: string },
) {
  return async dispatch => {
    fdb.ref(`user/${id}`).update(fields)

    dispatch(editUserEnd())
  }
}

export type LinkageFields = {
  url: string
  headersText: string
}

export async function updateLinkage(user: User, fields: LinkageFields) {
  const integration: Integration = {
    url: fields.url,
    headersText: fields.headersText,
    mode: 'test',
    badCount: 0,
    logs: [],
  }

  await fdb.ref(`user/${user.id}`).update({ integration })
  await sleep(1000)

  const res = await apiClient
    .post(`/zyuutenIntegrationTest`, {
      userId: user.id,
      siteName: user.title,
    })
    .catch(() => {
      fdb.ref(`user/${user.id}/integration`).update({
        badCount: 1,
        mode: 'off',
      })
      return { status: 0, data: 'bad' }
    })

  // const integraiton = (
  //   await fdb.ref(`user/${user.id}/integration`).once('value')
  // ).val() as Integration

  await sleep(2000)
  if (res.status !== 200 || res.data !== 'ok') {
    // if (res.status !== 200 || integraiton.mode === 'test') {
    await fdb.ref(`user/${user.id}/integration`).update({
      badCount: 1,
      mode: 'off',
    })
  }
}

export function stopIntegration(user: User) {
  return fdb.ref(`user/${user.id}/integration`).update({ mode: 'off' })
}

const DATE1 = 1000 * 60 * 60 * 24

function contactFilter(st?: string, et?: string, ts?: number) {
  if (!st || !et || !ts) return true

  return (
    +new Date((st || '1970-01-01') + 'T00:00:00') <= ts * 1000 &&
    ts * 1000 <= +new Date((et || '3000-01-01') + 'T00:00:00') + DATE1
  )
}

type DevicesById = { [deviceId: string]: { [sessionId: string]: SessionRaw } }
const isZeyuuten = (session: SessionRaw) =>
  session.category === 'zyuutenmieruka'

const isZeyuutenSession = ([deviceId, sbi]: [string, DevicesById[string]]) =>
  Object.entries(sbi).some(([sid, session]) => isZeyuuten(session))

const getFirstLogTs = async (
  path: string,
  cache: Cache,
): Promise<null | number> => {
  if (cache[path]) return cache[path]

  const logsSnap = await fdb
    .ref(path)
    .orderByChild('timestamp')
    .limitToFirst(1)
    .once('value')

  if (!logsSnap.exists()) return null

  const logs = logsSnap.val() as { [id: string]: LogRaw }
  const ts = Math.floor(Object.values(logs)[0].timestamp)

  return Math.floor(ts / 1000)
}

export async function getSessions(user: User, cache: Cache) {
  const sessionRef = fdb.ref(`user-device-session/${user.id}`)
  // .orderByChild('category')

  const devicesById: DevicesById = (await sessionRef.once('value')).val()

  const fetchSessions = Object.entries(devicesById)
    .filter(isZeyuutenSession)
    .map(async ([deviceId, sessionById]) => {
      // console.log(sessionById)

      const sessionTimeLoads = Object.entries(sessionById).map(
        async ([id, session]): Promise<Session | null> => {
          const path = `user-device-session-log/${user.id}/${deviceId}/${id}/logs`
          const createdAt = await getFirstLogTs(path, cache)

          const current = !session.threasholds
          const comp: Session = { id, ...session, current }

          // if (!createdAt) return comp
          if (!createdAt) return null
          cache[path] = createdAt

          return { ...comp, createdAt }
        },
      )
      const sessions = (await Promise.all(sessionTimeLoads))
        .filter(notNull)
        .filter(v =>
          contactFilter(
            user.contactStartDate,
            user.contactEndDate,
            v.createdAt,
          ),
        )

      return {
        deviceId,
        sessions: _.sortBy(sessions, [s => -s.createdAt]),
      }
    })

  const sessionsList: {
    deviceId: string
    sessions: Session[]
  }[] = await Promise.all(fetchSessions)

  return [sessionsList, cache] as const
}

const notNull = <T>(item: T | null): item is T => item !== null

export async function loadSessionLogs(
  userId: string,
  sessionPath: string,
  to = 0,
) {
  const [deviceId, sessionId] = sessionPath.split('---')
  const path = `user-device-session-log/${userId}/${deviceId}/${sessionId}/logs`
  const logsSnap = await fdb
    .ref(path)
    .orderByChild('timestamp')
    .once('value')

  if (!logsSnap.exists()) return []
  const logs: LogRaw[] = []

  logsSnap.forEach(snap => {
    logs.push(snap.val() as LogRaw)
  })
  return logs
}

export const isRecordingWithoutKeep = (sessionId: number, device?: Device) => {
  return (
    device &&
    sessionId === device.currentSessionId &&
    device.info.status === 'running'
  )
}
export function useSessionList(user: User) {
  const [sessionList, setSessionList] = useSessions()
  const [sessionStarts, setSessionStarts] = useSessionTimes()

  useEffect(() => {
    if (!user.allowDownload) return
    getSessions(user, { ...sessionStarts }).then(([sessions, cache]) => {
      setSessionList(sessions)
      setSessionStarts(cache)
    })
    // eslint-disable-next-line
  }, [user.id])

  return sessionList
}

export function useSessionLogs(
  userId: string,
  sessionPath: string,
  sessionId: number,
) {
  const [logs, setLogs] = useState<{ [sessionPath: string]: LogRaw[] }>({})
  const [subscribing, setSubscribing] = useState<{
    [sessionPath: string]: boolean
  }>({})
  const [deviceId] = sessionPath.split('---')
  const device = useSelector<State, Device | undefined>(s =>
    getConcreteMonitorDevice(s, deviceId),
  )

  const isLive = isRecordingWithoutKeep(sessionId, device)

  useEffect(() => {
    if (subscribing[sessionPath]) {
      if (!isLive) {
        setLogs(v => ({
          ...v,
          [sessionPath]: _.sortBy(v[sessionPath] || [], ['timestamp']),
        }))
      }
      return
    }
    setSubscribing(v => ({ ...v, [sessionPath]: true }))
    const time = Date.now()

    loadSessionLogs(userId, sessionPath, time).then(nlogs => {
      if (!isLive) {
        setLogs(v => ({ ...v, [sessionPath]: nlogs }))
      }
      setLogs(v => ({ ...v, [sessionPath]: nlogs.reverse() }))
      subscribeSessionLogs(
        userId,
        sessionPath,
        (nv, sp) => {
          setLogs(v => {
            const a: typeof v = { ...v }
            const ologs = v[sp] || []
            let i = 0

            for (const log of ologs) {
              if (nv.timestamp > log.timestamp) break
              i += 1
            }
            const tails = ologs.splice(i)

            a[sp] = [...ologs, nv, ...tails]

            return a
          })
        },
        time,
      )
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionPath, userId, isLive])

  return [logs[sessionPath] || [], isLive] as const
}

export function subscribeSessionLogs(
  userId: string,
  sessionPath: string,
  childAdded: (log: LogRaw, sessionPath: string) => void,
  time = 0,
) {
  const [deviceId, sessionId] = sessionPath.split('---')
  const path = `user-device-session-log/${userId}/${deviceId}/${sessionId}/logs`

  return fdb
    .ref(path)
    .orderByChild('timestamp')
    .startAt(time)
    .on('child_added', snap => {
      const res = snap.val() as LogRaw

      childAdded(res, sessionPath)
    })
}

export async function resetTestMode(user: User) {
  const snap = await fdb.ref(`user/${user.id}/integration`).once('value')

  if (!snap.exists()) return
  if ((snap.val() as Integration).mode !== 'test') return

  await fdb.ref(`user/${user.id}/integration`).update({
    badCount: 1,
    mode: 'off',
  })
}
