import * as React from 'react'
import { connect } from 'react-redux'
import { Typography } from '@material-ui/core'
import { State as RootState } from '../../types'

// import * as selectors from './selectors'
import { getTimestampStr } from '../../state/Clock/selectors'

type Props = {
  timestampStr: string
}

const TimestampLabel: React.SFC<Props> = props => {
  return <Typography variant="body1">{props.timestampStr}</Typography>
}

const ms = (state: RootState) => ({
  timestampStr: getTimestampStr(state),
})

const conn = connect(ms, {})

export default conn(TimestampLabel)
