import { Typography } from '@material-ui/core'
import range from 'lodash/range'
import React, { useRef } from 'react'
import { useSize } from 'react-hook-size'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { SmallButton } from '../../components'
import { getConcreteMonitorDevice } from '../../state/DeviceById/selectors'
import { getShowSurfaceTemp } from '../../state/SystemCache/selectors'
import { Device, Log, State } from '../../types'
import { tempLabel, timeLabelHmd } from '../../utils'
import CompTimeHeader from './components/CompTimeHeader'
import RegistanceTable from './components/RegistanceTable'
import ConcreteDeviceHeader from './ConcreteDeviceHeader'
import GraphBody from './GraphBody'
import LastTimeLabel from './LastTimeLabel'
import { useGraphConfigModal, useViewMode } from './useViewConfig'

type Props = {
  deviceId: string
  colNum: 1 | 2 | 4
}
const CardContainerWrap: React.FC<{
  width: number
  visibleTemp: boolean
}> = props => {
  return (
    <div
      style={{
        overflow: 'hidden',
        height: `${(props.width || 0) * 0.45}px`,
        // height: `${(props.width || 0) * (props.visibleTemp ? 0.495 : 0.45)}px`,
      }}
    >
      {props.children}
    </div>
  )
}

const ConcreteDevice = (props: Props & { width: number }) => {
  const device = useSelector<State, Device | undefined>(state =>
    getConcreteMonitorDevice(state, props.deviceId),
  )

  const showSurfaceTemp = useSelector(getShowSurfaceTemp)
  const [viewMode] = useViewMode()
  const [, setGraphModalOpen] = useGraphConfigModal()

  if (!device) {
    return (
      <Typography variant="body1">Loading: {`${props.deviceId}`}</Typography>
    )
  }

  const lastLog =
    device.lastLogs[
      device.currentSessionId + (device.info.keepedNewSession ? 1 : 0)
    ]

  if (device.info.status !== 'running') {
    return (
      <>
        <ConcreteDeviceHeader device={device} col={props.colNum} />
        <CardContainerWrap width={props.width} visibleTemp={showSurfaceTemp}>
          <CardContainer
            col={props.colNum}
            width={props.width}
            data-temp={showSurfaceTemp}
          >
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div className="rec-label" data-stop="true">
                <Typography variant="body1" color="inherit">
                  ●記録停止中
                </Typography>
              </div>
            </div>
            <div />
          </CardContainer>
        </CardContainerWrap>
      </>
    )
  }

  /* NOTE: 擬似的に停止を表示する */
  if (device.info.keepExpired) {
    return (
      <>
        <ConcreteDeviceHeader device={device} col={props.colNum} />
        <CardContainerWrap width={props.width} visibleTemp={showSurfaceTemp}>
          <CardContainer col={props.colNum} width={props.width}>
            <CenterBox>
              <Typography>30分間レコーダと接続できなかったため、</Typography>
              <Typography>次の記録開始まで切断されます。</Typography>
            </CenterBox>
          </CardContainer>
        </CardContainerWrap>
      </>
    )
  }

  return (
    <>
      <ConcreteDeviceHeader device={device} col={props.colNum} />
      <CardContainerWrap width={props.width} visibleTemp={showSurfaceTemp}>
        <CardContainer
          col={props.colNum}
          width={props.width}
          data-show-st={showSurfaceTemp}
        >
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div className="rec-label">
              <Typography variant="body1" color="inherit">
                ●記録中
              </Typography>
            </div>

            <Typography variant="body1" style={{ color: 'white' }}>
              {lastLog ? (
                <>
                  <SmallButton
                    onClick={() => setGraphModalOpen(true)}
                    style={{
                      display: viewMode === 'table' ? 'none' : 'inline',
                    }}
                  >
                    グラフ設定
                  </SmallButton>
                  <LastTimeLabel timestamp={lastLog.timestamp} />
                </>
              ) : (
                'ログが足りません'
              )}
            </Typography>
          </div>
          {!lastLog && <div />}
          {lastLog &&
            (viewMode === 'table' ? (
              <TableBody lastLog={lastLog} visibleSurface={showSurfaceTemp} />
            ) : (
              <GraphBody device={device} />
            ))}
        </CardContainer>
      </CardContainerWrap>
    </>
  )
}

const TableBody = ({
  lastLog,
  visibleSurface,
}: {
  lastLog: Log
  visibleSurface: boolean
}) => {
  const surfaceTemps = [''].concat(
    range(1, 12).map(i =>
      (lastLog.fields.sensors[i - 1]?.level ?? 0) === 0
        ? ''
        : tempLabel(lastLog.fields.sensors[i - 1]?.surfaceTemp, ''),
    ),
  )

  return (
    <div className={'log-table'}>
      <CompTimeHeader
        title="締固完了時刻"
        color="lime"
        labels={range(0, 12).map(i =>
          timeLabelHmd(lastLog.compTimes[i]?.compactionEnd),
        )}
      />
      <CompTimeHeader
        title="充填完了時刻"
        color="aqua"
        labels={range(0, 12).map(i =>
          timeLabelHmd(lastLog.compTimes[i]?.fillingEnd),
        )}
      />
      {visibleSurface && (
        <CompTimeHeader
          title="現在温度(℃)"
          color="orange"
          labels={surfaceTemps}
        />
      )}
      <RegistanceTable sensors={lastLog.fields.sensors} />
    </div>
  )
}

const ResizeConcreteDevice = (props: Props) => {
  const ref = useRef<HTMLDivElement | null>(null)
  const { width } = useSize(ref)

  return (
    <div
      ref={ref}
      style={{
        display: 'grid',
        gridTemplateRows: 'max-content',
        marginBottom: '1.6rem',
      }}
    >
      <ConcreteDevice {...props} width={width || 0} />
    </div>
  )
}

const baseWidth = 1057

export const CardContainer = styled.div<{ col: number; width: number }>`
  border: solid black 1px;
  border-radius: 4px;
  width: ${baseWidth}px;
  height: ${baseWidth * 0.45}px;
  min-width: 100px;
  padding: 4px;
  background: black;
  color: white;
  display: grid;
  grid-template-rows: max-content;
  transform: scale(${({ width }) => width / (baseWidth + 10)});
  transform-origin: top left;
  p {
    font-size: 22px !important;
  }
  .times .cell p {
    font-size: 18px !important;
  }

  .rec-label {
    background: white;
    color: red;
    padding: 4px;

    &[data-stop='true'] {
      color: black;
    }
  }
  .log-table {
    display: grid;
    grid-template-rows: 10% 10% 80%;
  }
  &[data-show-st='true'] {
    /* height: ${baseWidth * 0.495}px; */
    .log-table {
      grid-template-rows: 10% 10% 10% 70%;
    }
  }
`

const CenterBox = styled.div`
  display: grid;
  height: 460px;
  align-content: space-evenly;
  justify-content: center;
  text-align: center;
  vertical-align: middle;
  p {
    font-size: 40px !important;
  }
`

export default ResizeConcreteDevice
