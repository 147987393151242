const {
  NODE_ENV,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_SERVER_KEY,
  REACT_APP_GIT_REV,
  REACT_APP_ENV,
  REACT_APP_GOOGLE_MAPS_API_KEY,
  STORYBOOK_GOOGLE_MAPS_API_KEY,
  REACT_APP_ENCRYPT_KEY,
} = process.env

const isDev = NODE_ENV === 'development' || REACT_APP_ENV === 'development'

const configDevelopment = {
  host: 'https://staging-zyuuten-10fd4.web.app',
}
const configProduction = {
  host: 'https://juten.mieruka.online',
}

console.log(isDev)
if (isDev) {
  console.log(REACT_APP_FIREBASE_DATABASE_URL)
}

export const formKey = 'mclient'
const config = {
  // keepExpiredMinutes: 0.1,
  keepExpiredMinutes: 40,
  isDev,
  tabBarHeight: 40,
  gitrev: REACT_APP_GIT_REV || '',
  firebase: {
    apiKey: REACT_APP_FIREBASE_API_KEY || '',
    authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN || '',
    databaseURL: REACT_APP_FIREBASE_DATABASE_URL || '',
    storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET || '',
    projectId: REACT_APP_FIREBASE_PROJECT_ID || '',
    messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID || '',
    serverKey: REACT_APP_FIREBASE_SERVER_KEY || '',
  },
  clockIntervalMs: 5000,
  googleMaps: {
    apiKey:
      REACT_APP_GOOGLE_MAPS_API_KEY || STORYBOOK_GOOGLE_MAPS_API_KEY || '',
    zoom: 15,
  },
  encryptKey: REACT_APP_ENCRYPT_KEY || '',
  concrete: {},
  downloadSoftLink:
    'https://firebasestorage.googleapis.com/v0/b/jutenmieruka.appspot.com/o/%E3%83%86%E3%82%99%E3%83%BC%E3%82%BF%E5%8F%96%E8%BE%BC.xlsm?alt=media&token=b9b45dca-201b-473b-bca6-9b16ed92b214',
  ...(isDev ? configDevelopment : configProduction),
}

export default config
