import {
  Button,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Paper,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/Dehaze'
import _ from 'lodash'
import * as React from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useSessionStorage } from 'react-use'
import { useBreakpoints } from 'react-use-breakpoints'
import styled from 'styled-components'
import { getLoginUser, getLoginUserTitle } from '../../state/Auth/selectors'
import { startTimer } from '../../state/Clock/operations'
import { getVisibleConcreteMonitorDeviceIds } from '../../state/DeviceById/selectors'
import {
  loadDevices,
  resetTestMode,
  syncUserData,
  useSessionList,
} from '../../state/Firebase/operations'
import { getShowItgLog } from '../../state/SystemCache/selectors'
import { Integration, IntegrationLogs, State } from '../../types'
import NavBar from '../NavBarContainer'
import TimestampLabel from '../System/TimestampLabel'
import { setShowSurfaceTemp } from '../../state/SystemCache/operations'
import ConcreteConfigModal from './ConcreteConfigModal'
import ConcreteDevice from './ConcreteDevice'
import GraphConfigModal from './GraphConfigModal'
import LinkageConfigModal from './LinkageConfigModal'
import { useViewMode } from './useViewConfig'
import ViewSwitcher from './ViewSwitcher'

const colNums: ColNum[] = [1, 2, 4]
const btnStyles = {
  lineHeight: '1rem',
  minWidth: '100px',
  padding: '4px',
  display: 'block',
}

type ColNum = 1 | 2 | 4
type Props = {
  deviceIds: string[]
  title: string
  loadDevices: () => void
  startTimer: () => void
  syncUserData: () => void
}

export function logTextTop(logs: IntegrationLogs) {
  const lines = _.map(
    logs,
    (log, time) =>
      `[${new Date(Number(time)).toLocaleString('ja-JP')}] ${log.status ||
        '---'} ${log.message}`,
  )

  lines.sort(() => -1)

  return lines.slice(0, 5)
}
const Word = styled.span`
  display: inline-block;
`

function ConcreteMonitorAppPage(props: Props) {
  React.useEffect(() => {
    props.loadDevices()
    props.syncUserData()
    props.startTimer()
    // eslint-disable-next-line
  }, [])

  const [modalOpen, setModalOpen] = useSessionStorage<boolean>(
    'modal_open',
    false,
  )
  const [linkageModalOpen, setLinkageModalOpen] = useSessionStorage<boolean>(
    'linkage_modal_open',
    false,
  )
  const showItgLog = useSelector(getShowItgLog)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [colNum, setColNum] = useSessionStorage<ColNum>('col_num', 1)
  const { breakpoint } = useBreakpoints()
  const user = useSelector(getLoginUser)
  const [, , setViewMode] = useViewMode()
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (!user.allowSurfaceTemp) {
      setViewMode('table')
      dispatch(setShowSurfaceTemp(false))
    }
    // eslint-disable-next-line
  }, [user.allowSurfaceTemp])
  const openMenu = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  const isMobile = ['sm', 'xs'].includes(breakpoint)

  useSessionList(user) // pre fetching for downlaod page

  React.useEffect(() => {
    if (isMobile) {
      setColNum(1)
    }
  }, [isMobile, setColNum])

  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const cw: ColNum = 12 / colNum
  const { integration } = user
  const DownloadButton = (
    <div>
      <a href="/download" style={{ textDecoration: 'none', color: 'black' }}>
        <Button
          style={{
            ...btnStyles,
            minHeight: '56px',
          }}
        >
          <Word>記録データ</Word>
          <Word>ダウンロード</Word>
        </Button>
      </a>
    </div>
  )

  return (
    <div>
      <NavBar />
      <Grid container justify={'center'}>
        <Grid item xs={12} sm={12} md={12}>
          <Hidden smUp>
            <div
              style={{
                padding: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <div style={{ display: 'flex' }}>
                <div>
                  <Typography variant={'h5'}>{props.title}</Typography>
                  <TimestampLabel />
                </div>
              </div>
              <div>
                <IconButton
                  aria-label="more"
                  aria-controls="long-menu"
                  aria-haspopup="true"
                  onClick={openMenu}
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="action-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={closeMenu}
                >
                  {user.allowDownload && (
                    <a
                      href="/download"
                      style={{ textDecoration: 'none', color: 'black' }}
                    >
                      <MenuItem>記録データダウンロード</MenuItem>
                    </a>
                  )}
                  {user.allowIntegration && (
                    <MenuItem
                      onClick={() => {
                        setLinkageModalOpen(true)
                        closeMenu()
                      }}
                    >
                      データ連携設定
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={() => {
                      setModalOpen(true)
                      closeMenu()
                    }}
                  >
                    表示設定
                  </MenuItem>
                </Menu>
              </div>
            </div>
          </Hidden>
          <Hidden xsDown>
            <div
              style={{
                padding: '12px',
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <div style={{ display: 'flex' }}>
                <div>
                  <Typography variant={'h5'}>{props.title}</Typography>
                  <TimestampLabel />
                </div>
              </div>
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'max-content',
                  justifyContent: 'right',
                  gridAutoFlow: 'column',
                }}
              >
                {user.allowIntegration && (
                  <>
                    <IntegrationStatus integration={integration} />

                    <Button
                      onClick={() => setLinkageModalOpen(true)}
                      style={{ maxHeight: '56px', ...btnStyles }}
                    >
                      <Word>データ</Word>
                      <Word>連携設定</Word>
                    </Button>
                  </>
                )}
                {user.allowDownload && DownloadButton}
                <Button
                  onClick={() => setModalOpen(true)}
                  style={{ maxHeight: '56px', ...btnStyles }}
                >
                  表示設定
                </Button>
              </div>
            </div>
          </Hidden>
          <Paper style={{ width: '100%' }}>
            <Hidden mdUp>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div>
                  {user.allowIntegration && (
                    <IntegrationStatus integration={integration} />
                  )}
                </div>
                <ViewSwitcher />
                <div style={{ display: 'flex' }}>
                  <Button color={'primary'}>1列</Button>
                  <Button disabled>2列</Button>
                  <Button disabled>4列</Button>
                </div>
              </div>
            </Hidden>
            <Hidden smDown>
              <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                <ViewSwitcher />
                {colNums.map(i => (
                  <Button
                    key={`col${i}`}
                    variant={'contained'}
                    color={colNum === i ? 'primary' : 'default'}
                    onClick={() => setColNum(i)}
                  >
                    {i}列
                  </Button>
                ))}
              </div>
            </Hidden>
            {user.allowIntegration && showItgLog && (
              <LogPreview>
                {integration?.logs &&
                  logTextTop(integration?.logs || {}).map((text, i) => (
                    <pre title={text} key={i}>
                      {text}
                    </pre>
                  ))}
              </LogPreview>
            )}
            <Grid container justify="flex-start">
              {props.deviceIds.map(deviceId => (
                <Grid key={deviceId} item xs={12} sm={cw}>
                  <ConcreteDevice deviceId={deviceId} colNum={colNum} />
                </Grid>
              ))}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      <ConcreteConfigModal
        modalOpen={modalOpen}
        handleConcreteModalClose={() => setModalOpen(false)}
      />
      <LinkageConfigModal
        modalOpen={linkageModalOpen}
        handleModalClose={() => {
          setLinkageModalOpen(false)
          resetTestMode(user)
        }}
      />
      <GraphConfigModal />
    </div>
  )
}

export function IntegrationStatus({
  integration,
}: {
  integration: Integration | undefined
}) {
  const [inteChanged, setInteChanged] = React.useState<number>(0)

  const bcV = integration?.badCount || -1

  React.useEffect(() => {
    setInteChanged(v => v + 1)
  }, [bcV])

  const viewError = inteChanged > 1 && !!integration?.badCount

  return (
    <div>
      <Typography style={{ padding: '1.4rem 0.5rem 0' }}>
        データ連携：
        {integration?.mode === 'test' ? (
          <span>テスト中</span>
        ) : integration?.mode === 'production' ? (
          <span style={{ color: 'green' }}>接続中</span>
        ) : (
          <span style={{ color: 'red' }}>停止</span>
        )}
      </Typography>
      {viewError && (
        <Typography
          style={{ padding: '1.4rem 0.5rem 0' }}
          variant="caption"
          color="error"
        >
          ※データ連携でエラーが発生しました。
        </Typography>
      )}
    </div>
  )
}

const LogPreview = styled.div`
  margin-top: 8px;
  border: solid 1px gray;
  pre {
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 14px;
    margin: 2px;
  }
`

type OProps = {}

const conn = connect(
  (state: State, op: OProps) => ({
    deviceIds: getVisibleConcreteMonitorDeviceIds(state),
    title: getLoginUserTitle(state),
  }),
  {
    loadDevices,
    startTimer,
    syncUserData,
  },
)

export default conn(ConcreteMonitorAppPage)
