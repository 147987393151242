import axios from 'axios'
import _ from 'lodash'
import moment from 'moment'
import config from '../config'
import { Log, LogRaw } from '../types'

export const timeLabel = (timestamp: number) => {
  const m = moment(timestamp)

  return m.format(`YYYY/MM/DD HH:mm:ss (${m.fromNow()})`)
}

export function timeLabelHmd(ts: number | undefined): string {
  if (!ts) return ''
  return moment(ts).format('HH:mm:ss')
}

export const toLog = (old: LogRaw, id: string = '-'): Log => {
  return {
    id,
    timestamp: old.timestamp,
    timeLabel: timeLabel(old.timestamp),
    fields: old.fields,
    compTimes: old.compTimes || {},
  }
}

export function makeCSV(logs: Log[]): string {
  const log = logs.shift()

  if (!log) {
    return 'noLog'
  }
  const head = ['timestamp', 'timestampStr', 'id', ...Object.keys(log.fields)]

  const toLine = (log: Log) => {
    return [
      log.timestamp,
      moment(log.timestamp).format('YYYY-MM-DD HH:mm:ss'),
      log.id,
      ..._.values(log.fields),
    ].map(String)
  }
  const cells = [head, ...logs.map(toLine)]

  return cells.map(vs => vs.join(',')).join('\n')
}

export function download(text: string, name: string) {
  const href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(text)
  const a = document.createElement('a')

  a.setAttribute('href', href)
  a.setAttribute('download', name + '.csv')
  document.body?.appendChild(a)
  setTimeout(() => {
    a.click()
    a.remove()
  }, 2000)
}

export const apiClient = axios.create({
  baseURL: config.host,
})

export const sleep = (msec: number) =>
  new Promise(resolve => setTimeout(resolve, msec))

export const tempLabel = (v: number | undefined | null, error = ''): string => {
  if (v === undefined || v === null) return error
  const n = Number(v / 10)

  if (n <= -50) return error
  return n.toFixed(1)
}

export const tempLabelNum = (
  v: number | undefined | null,
): number | undefined => {
  if (v === undefined || v === null) return undefined
  const n = Number(v / 10)

  if (n <= -50) return undefined
  return n
}

export const streLabel = (v: number | undefined | null): string => {
  if (v === undefined || v === null) return '0'

  return v.toFixed(2)
}
