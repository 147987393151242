import {
  FormControlLabel,
  IconButton,
  Radio,
  Typography,
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import moment from 'moment'
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { useBreakpoints } from 'react-use-breakpoints'
import { getConcreteMonitorDevice } from '../../state/DeviceById/selectors'
import { getShowName } from '../../state/SystemCache/selectors'
import { Device, Session, State } from '../../types'

type Props = {
  current: number | undefined
  deviceId: string
  sessions: Session[]
}

const toDateTimeLabel = (time: number) =>
  moment(time * 1000).format('YYYY月MM月DD日HH時mm分ss秒')

const toLabel = (session: Session) => {
  if (!session.createdAt) return 'ログなし'
  return toDateTimeLabel(session.createdAt)
}

export const isRecording = (session: Session, device?: Device) => {
  return (
    device &&
    device.info.status === 'running' &&
    session.id === `${device.currentSessionId}` &&
    !device.info.keepExpired
  )
}

type ItemProps = { session: Session; device?: Device; deviceId: string }
function DeviceLogItem({ session, device, deviceId }: ItemProps) {
  const deviceSessionId = `${deviceId}---${session.id}`

  return (
    <div style={{ display: 'flex' }}>
      <FormControlLabel
        value={deviceSessionId}
        style={{ marginRight: 0 }}
        control={<Radio />}
        label={toLabel(session)}
      />
      {isRecording(session, device) && (
        <span style={{ color: 'red', padding: '8px 2px 0 1rem' }}>●記録中</span>
      )}
    </div>
  )
}

function DeviceLogList({ deviceId, sessions }: Props) {
  const device = useSelector<State, Device | undefined>(s =>
    getConcreteMonitorDevice(s, deviceId),
  )
  const [open, setOpen] = useState<boolean>(false)
  const showName = useSelector(getShowName)

  const { breakpoint } = useBreakpoints()
  const isMobile = ['xs'].includes(breakpoint)

  if (!device) return null

  if (isMobile) {
    return (
      <div style={{ paddingBottom: '1.5rem' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div>
            <Typography variant="h6">{deviceId}</Typography>
            {device?.info.label && (
              <Typography variant="h6">({device?.info.label})</Typography>
            )}
          </div>
          <IconButton onClick={() => setOpen(v => !v)}>
            <AddIcon />
          </IconButton>
        </div>
        <div style={{ display: open ? 'grid' : 'none' }}>
          {sessions.map(session => (
            <DeviceLogItem
              key={session.id}
              session={session}
              deviceId={deviceId}
              device={device}
            />
          ))}
        </div>
      </div>
    )
  }

  return (
    <div style={{ paddingBottom: '1.5rem' }}>
      <Typography variant="h6" style={{ width: '312px' }}>
        {deviceId}
      </Typography>
      {showName && device?.info.label && (
        <Typography variant="h6" style={{ width: '312px' }}>
          ({device.info.label})
        </Typography>
      )}
      <div style={{ display: 'grid' }}>
        {sessions.slice(0, 10).map(session => (
          <DeviceLogItem
            key={session.id}
            session={session}
            device={device}
            deviceId={deviceId}
          />
        ))}
        {sessions.length > 10 && (
          <details>
            <summary>すべて表示</summary>
            <div>
              {sessions.slice(10).map(session => (
                <DeviceLogItem
                  key={session.id}
                  session={session}
                  device={device}
                  deviceId={deviceId}
                />
              ))}
            </div>
          </details>
        )}
      </div>
    </div>
  )
}
export default DeviceLogList
