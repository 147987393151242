import { Typography } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { useBreakpoints } from 'react-use-breakpoints'
import styled from 'styled-components'
import { getLoginUser } from '../../state/Auth/selectors'
import { getConcreteMonitorDevice } from '../../state/DeviceById/selectors'
import { useSessionLogs } from '../../state/Firebase/operations'
import { Device, Session, State } from '../../types'

const Style = styled.div`
  .head {
    margin: 12px 4px 12px 12px;
    button {
      margin-left: 8px;
    }

    display: flex;
    justify-content: flex-end;
    > div {
      max-width: 500px;
    }
  }
  &[data-mobile='true'] {
    .head > div {
      max-width: 280px;
    }
  }
  th.ch-col {
    font-size: 0.8rem;
    &:nth-child(n + 4) {
      width: 7%;
    }
  }
  td[data-level] {
    color: rgba(0, 0, 0, 0);
    &[data-level='0'] {
      background: black;
    }
    &[data-level='1'] {
      background: #ec3323;
    }
    &[data-level='2'] {
      background: #ffffff;
    }
    &[data-level='3'] {
      background: #7bb1ff;
    }
    &[data-level='4'] {
      background: #aaaaaa;
    }
    &[data-level='5'] {
      background: #fffe55;
    }
    &[data-level='6'] {
      background: #9fcd63;
    }
  }
  table.note {
    td:nth-child(2) {
      width: 150px;
    }
    td {
      text-align: left;
      width: 80px;
      &[data-level] {
        width: 10%;
        border: gray solid 1px;
      }
      /* &:not([data-level]) {
        width: 28%;
      } */
    }
  }
  &[data-mobile='true'] {
    td:nth-child(2) {
      width: 150px;
    }
    td {
      width: 54px;
    }
    width: 100%;
    td,
    th {
      font-size: 9px;
    }
  }
  .scale-font {
    @media (max-width: 500px) {
      font-size: 0.6rem;
    }
  }
`

const CellTable = styled.table`
  border-spacing: 0;
  width: 100%;
  th {
    background: #f7cffc;
  }
  tr:first-child {
    td,
    th {
      border-top: gray solid 1px;
    }
  }
  td,
  th {
    border-right: gray solid 1px;
    border-bottom: gray solid 1px;
    &:first-child {
      border-left: gray solid 1px;
    }
  }
  td {
    font-family: monospace;
    text-align: right;
  }
  &[data-mobile='true'] {
    width: 100%;
    td,
    th {
      font-size: 9px;
    }
  }
`

type Props = {
  deviceId: string
  session: Session
  sessionPath: string
}
function SessionPreview({ session, sessionPath, deviceId }: Props) {
  const user = useSelector(getLoginUser)
  const [logs, isLive] = useSessionLogs(
    user.id,
    sessionPath,
    Number(session.id),
  )
  const device = useSelector<State, Device | undefined>(state =>
    getConcreteMonitorDevice(state, deviceId),
  )

  const { breakpoint } = useBreakpoints()
  const expired = isLive && device?.info.keepExpired

  console.log({ device })
  console.log(device?.updatedAt)
  console.log({ isLive, k: device?.info.keepExpired })

  const isMobile = ['xs', 'sm'].includes(breakpoint)

  return (
    <Style key={'ses-' + sessionPath} data-mobile={isMobile}>
      <div className="head">
        <div>
          <table className="note">
            <tbody>
              <tr>
                <td data-level={1}></td>
                <td>センサー不良・未接続</td>
                <td data-level={0}></td>
                <td>CHオフ</td>
              </tr>
              <tr>
                <td data-level={2}></td>
                <td>空気</td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td data-level={3}></td>
                <td>水</td>
                <td data-level={5}></td>
                <td>締固め途中</td>
              </tr>
              <tr>
                <td data-level={4}></td>
                <td>コンクリート</td>
                <td data-level={6}></td>
                <td>締固め完了</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div
        style={{ display: expired || session.forceStopped ? 'block' : 'none' }}
      >
        <Typography color="secondary" className="scale-font">
          ※レコーダとの接続が途切れたためデータに欠損が生じております。
        </Typography>
      </div>
      <CellTable data-mobile={isMobile}>
        <thead>
          <tr>
            {['No.', 'Date', 'Time', ..._.range(1, 12).map(i => `CH${i}`)].map(
              label => (
                <th key={label} className="ch-col">
                  {label}
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody key={`${sessionPath}_${isLive}`}>
          {logs.map((log, i) => {
            const time = log.timestamp ? moment(log.timestamp) : null

            return (
              <tr key={`${i}_${log.timestamp}`}>
                <td>{i + 1}</td>
                <td>{time?.format('YYYY/MM/DD')}</td>
                <td>{time?.format('HH:mm:ss')}</td>
                {_.range(1, 12).map(si => (
                  <td key={si} data-level={log.fields.sensors[si].level}>
                    {log.fields.sensors[si].level}
                  </td>
                ))}
              </tr>
            )
          })}
        </tbody>
      </CellTable>
      {logs?.length === 0 && (
        <div style={{ padding: '1rem' }}>
          <CircularProgress color="secondary" />
        </div>
      )}
    </Style>
  )
}
export default SessionPreview
