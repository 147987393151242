import React from 'react'

import { Modal, Button } from '@material-ui/core'

import { ModalCard } from '../../components'
import LinkageConfigForm from './LinkageConfigForm'

type Props = {
  modalOpen: boolean
  handleModalClose: () => void
}

function LinkageConfigModal(props: Props) {
  return (
    <Modal open={props.modalOpen} onClose={props.handleModalClose}>
      <ModalCard style={{ height: 'auto' }}>
        <LinkageConfigForm />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={props.handleModalClose}>閉じる</Button>
        </div>
      </ModalCard>
    </Modal>
  )
}

export default LinkageConfigModal
