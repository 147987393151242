import { connect } from 'react-redux'
import { passwordLogin } from '../../state/Firebase/operations'
import { updateLoginField } from '../../state/LoginForm/actions'
import {
  getLoginField,
  getLoginFieldError,
} from '../../state/LoginForm/selectors'
import { FieldError, LoginField, State } from '../../types'
import LoginForm, { Props } from './components/LoginForm'

type OProps = {
  nextPath?: string
}
type SProps = {
  field: LoginField
  error: FieldError
}
type DProps = {
  passwordLogin: (email: string, password: string, nextPath?: string) => void
  updateLoginField: (field: Partial<LoginField>) => void
}

const conn = connect<SProps, DProps, OProps, Props, State>(
  (state, op) => ({
    field: getLoginField(state),
    error: getLoginFieldError(state),
  }),
  { passwordLogin, updateLoginField },
  (sp, dp, op) => ({
    ...sp,
    onChangeField: dp.updateLoginField,
    onSubmit: () =>
      dp.passwordLogin(sp.field.email, sp.field.password, op.nextPath),
  }),
)

export default conn(LoginForm)
