import {
  Button,
  Card,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography,
} from '@material-ui/core'
import { Formik, FormikProps } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { getConcreteMonitorDevice } from '../../../state/DeviceById/selectors'
import { updateDeviceInfo } from '../../../state/Firebase/operations'
import { Device, State } from '../../../types'

const toj = JSON.stringify

type Props = {
  device?: Device
  updateDeviceInfo: (deviceId: string, fields: { [key: string]: any }) => void
}

type Fields = {
  label: string
  visible: boolean
  priority: number
}

function ConcreteTopicForm(props: Props) {
  const { device } = props

  if (!device) {
    return null
  }
  const fields: Fields = {
    label: device.info.label,
    visible: device.info.visible,
    priority: device.info.priority,
  }

  return (
    <div>
      <Formik
        initialValues={fields}
        validate={values => {
          const errors: Partial<Fields> = {}
          // if (!values.name) {
          //   errors.name = 'Required'
          // }

          return errors
        }}
        onSubmit={(values: Fields, { setSubmitting, setErrors }) => {
          props.updateDeviceInfo(device.id, values)
        }}
        render={({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldTouched,
          setFieldValue,
          isSubmitting,
        }: FormikProps<Fields>) => {
          const change = (name, e) => {
            e.persist()
            handleChange(e)
            setFieldTouched(name, true, false)
          }

          return (
            <Card>
              <form onSubmit={handleSubmit}>
                <Typography variant="h6">{device.id}</Typography>
                <TextField
                  name="label"
                  helperText={touched.label ? errors.label : ''}
                  error={touched.label && Boolean(errors.label)}
                  label="名称"
                  value={values.label}
                  onChange={change.bind(null, 'label')}
                  fullWidth
                />
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '2fr 2fr 1fr',
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={values.visible}
                        onChange={e => {
                          setFieldValue('visible', e.target.checked)
                        }}
                      />
                    }
                    label={'表示する'}
                  />
                  <TextField
                    name="priority"
                    helperText={touched.priority ? errors.priority : ''}
                    error={touched.priority && Boolean(errors.priority)}
                    type="number"
                    label="優先度"
                    value={values.priority}
                    onChange={change.bind(null, 'priority')}
                  />
                  <Button
                    disabled={toj(fields) === toj(values)}
                    color="primary"
                    onClick={e => handleSubmit()}
                  >
                    変更する
                  </Button>
                </div>
              </form>
            </Card>
          )
        }}
      />
    </div>
  )
}

type OProps = {
  deviceId: string
}

const conn = connect(
  (state: State, op: OProps) => ({
    device: getConcreteMonitorDevice(state, op.deviceId),
  }),
  {
    updateDeviceInfo,
  },
)

export default conn(ConcreteTopicForm)
