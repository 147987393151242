import { State } from '../../types'

export const getSystemCache = (state: State) => state.SystemCache
export const getDaySelectorToggle = (state: State, deviceId: string) =>
  getSystemCache(state).toggles[`dayselector_${deviceId}`] || false

export const getEditDevice = (state: State) => state.SystemCache.editDevice
export const getCreateDevice = (state: State) => state.SystemCache.createDevice

export const getEditUser = (state: State) => state.SystemCache.editUser
export const getCreateUser = (state: State) => state.SystemCache.createUser
export const getShowName = (state: State) => state.SystemCache.showName

export const getShowItgLog = (state: State) =>
  state.SystemCache.toggles[`configItgLog`] || false

export const getShowSurfaceTemp = (state: State) =>
  !!state.SystemCache.toggles[`configSurfaceTemp`]

// export const getShowItgLog = (state: State) =>
//   state.SystemCache.toggles[`configItgLog`] || false
