import { actionCreatorFactory } from 'typescript-fsa'
import { Device } from '../../types'

const actionCreator = actionCreatorFactory()

export const receiveDevice = actionCreator<Device>('receiveDevice')
export const receiveDeviceToken = actionCreator<{ id: string; token: string }>(
  'receiveDeviceToken',
)
export const updateDevice = actionCreator<Partial<Device>>('updateDevice')
export const removeDevice = actionCreator<{ id: string }>('removeDevice')
export const cleanDevice = actionCreator('cleanDevice')
