import { Button } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import { getLoginUser } from '../../state/Auth/selectors'
import { useViewMode } from './useViewConfig'

type Props = {}
function ViewSwitcher() {
  const { allowSurfaceTemp } = useSelector(getLoginUser)
  const [, rotateViewMode] = useViewMode()

  return (
    <Button
      style={{
        marginRight: '1rem',
        display: allowSurfaceTemp ? 'inline' : 'none',
      }}
      onClick={rotateViewMode}
    >
      表示切り替え
    </Button>
  )
}

export default ViewSwitcher
