import React from 'react'
import { connect, useSelector, useDispatch } from 'react-redux'

import { Typography, FormControlLabel, Checkbox } from '@material-ui/core'
import { getVisibleConcreteMonitorDeviceIds } from '../../../state/DeviceById/selectors'
import { State } from '../../../types'

import {
  getShowItgLog,
  getShowName,
  getShowSurfaceTemp,
} from '../../../state/SystemCache/selectors'
import { setShowName } from '../../../state/SystemCache/actions'
import {
  toggleShowItgLog,
  toggleShowSurfaceTemp,
} from '../../../state/SystemCache/operations'
import { getLoginUser } from '../../../state/Auth/selectors'
import ConcreteDeviceForm from './ConcreteDeviceForm'
import ConcreteTopicForm from './ConcreteTopicForm'

type Props = {
  deviceIds: string[]
}

function ConcreteConfigForm(props: Props) {
  const showName = useSelector(getShowName)
  const showItgLog = useSelector(getShowItgLog)
  const showSurfaceTemp = useSelector(getShowSurfaceTemp)
  const { allowSurfaceTemp } = useSelector(getLoginUser)

  const dispatch = useDispatch()

  return (
    <div style={{ padding: '12px' }}>
      <Typography variant="h4">表示設定</Typography>
      <ConcreteTopicForm />

      <div style={{ display: 'grid' }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={showItgLog}
              onChange={e => dispatch(toggleShowItgLog())}
            />
          }
          label={'データ連携ログ表示'}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={showName}
              onChange={e => {
                dispatch(setShowName(!showName))
              }}
            />
          }
          label={'名称表示'}
        />
        {allowSurfaceTemp && (
          <FormControlLabel
            control={
              <Checkbox
                checked={showSurfaceTemp}
                onChange={e => {
                  dispatch(toggleShowSurfaceTemp())
                }}
              />
            }
            label={'温度表示'}
          />
        )}
      </div>
      {props.deviceIds.map(id => (
        <ConcreteDeviceForm key={id} deviceId={id} />
      ))}
      <Typography variant="caption">
        ※優先度の数値が早い順番に表示されます。
        空白・未設定は最後に表示されます。
      </Typography>
    </div>
  )
}

const conn = connect((state: State) => {
  return {
    deviceIds: getVisibleConcreteMonitorDeviceIds(state, false),
  }
})

export default conn(ConcreteConfigForm)
