import { Box } from '@material-ui/core'
import { range } from 'lodash'
import React, { useEffect, useMemo } from 'react'
import { Chart } from 'react-google-charts'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getLoginUser } from '../../state/Auth/selectors'
import { useSessionLogs } from '../../state/Firebase/operations'
import { Device } from '../../types'
import { streLabel, tempLabel, tempLabelNum } from '../../utils'
import { useGraphConfig } from './useViewConfig'

type Props = {
  device: Device
}

function GraphBody({ device }: Props) {
  const user = useSelector(getLoginUser)

  const sessionPath = `${device.id}---${device.currentSessionId}`
  const [logs] = useSessionLogs(user.id, sessionPath, device.currentSessionId)
  const [config, setConfig] = useGraphConfig()
  const lastLog =
    device.lastLogs[
      device.currentSessionId + (device.info.keepedNewSession ? 1 : 0)
    ]

  const firstLog = logs[logs.length - 1]
  const [graphConfig, setGraphConfig] = useGraphConfig()

  useEffect(() => {
    if (!firstLog) return
    const start = firstLog.timestamp

    setGraphConfig({ ...graphConfig, start, end: +new Date() })

    const hideCh: Record<string, boolean> = {}

    range(1, 12).forEach(i => {
      hideCh[i] = firstLog.fields.sensors[i].level === 0
    })

    setConfig({ ...config, start, hideCh })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLog?.timestamp])

  const data = useMemo(() => {
    const keys = range(1, 12).filter(i => !config.hideCh[i])

    const header = ['time', ...keys.map(i => `CH${i}`)]

    return [
      header,
      ...logs.slice(0, 1000).map(log => {
        return [
          new Date(log.timestamp),
          ...keys
            .map(i => log.fields.sensors[i])
            .map(
              v =>
                ({
                  stre: Number(streLabel(v.surfaceStre)),
                  temp: tempLabelNum(v.surfaceTemp),
                }[config.view] || 0),
            ),
        ]
      }),
    ]
  }, [logs, config])

  const color = '#fff'

  if (logs.length === 0) {
    return <p>ロード中</p>
  }

  return (
    <Style>
      <div>
        <Chart
          chartType="LineChart"
          data={data}
          width="100%"
          height="400px"
          legendToggle
          options={{
            backgroundColor: '#000',

            chartArea: {
              top: '5%',
              bottom: '5%',
              right: '12%',
              left: '10%',
              backgroundColor: {
                stroke: '#fff',
                storkeWidth: 1,
              },
            },
            color: '#000',
            titleTextStyle: { color },
            hAxis: {
              gridlines: {
                count: -1,
                color: 'white',
                units: {
                  days: { format: ['yyyy/MM/dd', 'H:mm'] },
                  hours: { format: ['H:mm', 'H:mm'] },
                },
              },
              minorGridlines: {
                units: {
                  days: { format: ['yyyy/MM/dd', 'H:mm'] },
                  hours: { format: ['H:mm', 'H:mm'] },
                },
              },
              textStyle: { color },
              titleTextStyle: { color },
              viewWindow: {
                min: new Date(config.start),
                max: new Date(config.end),
              },
            },
            vAxis: {
              title: config.view === 'stre' ? 'N/mm2' : '℃',
              gridlines: { color: 'transparent' },
              textStyle: { color },
              titleTextStyle: { color },
              viewWindow: {
                min: config.down,
                max: config.up,
              },
            },
            legend: { textStyle: { color } },
          }}
        />
      </div>
      <div>
        {lastLog && (
          <Box className="state-panel">
            {range(1, 12).map(i => (
              <div key={i}>
                <div>CH{`${i}`.padStart(2, '0')}</div>
                <div className="params">
                  <div>
                    <div>
                      {tempLabel(
                        lastLog.fields.sensors[i - 1].surfaceTemp,
                        'Error',
                      )}
                    </div>
                    <div className="unit">℃</div>
                  </div>
                  <div>
                    <div>
                      {streLabel(lastLog.fields.sensors[i - 1].surfaceStre)}
                    </div>
                    <div className="unit">
                      N/mm<span>2</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Box>
        )}
      </div>
    </Style>
  )
}
const Style = styled.div`
  display: grid;
  grid-template-columns: 1fr max-content;
  font-size: 0.8rem;
  .state-panel {
    > div {
      padding: 0 4px;
      display: grid;
      grid-gap: 0.2rem;
      grid-template-columns: max-content 1fr;
      place-items: center;
      text-align: right;
      border: solid 1px;
      &:not(:last-child) {
        border-bottom: none;
      }
    }

    .params {
      width: 100%;
      > div {
        display: grid;
        grid-gap: 0.2rem;
        grid-template-columns: 1fr 3rem;
        width: 100%;
      }
    }
    .unit {
      text-align: left;
      span {
        vertical-align: top;
        font-size: 0.8rem;
      }
    }
  }
`

export default GraphBody
