import {
  Button,
  IconButton,
  Paper,
  Popover,
  RadioGroup,
} from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import MoreVertIcon from '@material-ui/icons/Dehaze'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { useBreakpoints } from 'react-use-breakpoints'
import styled from 'styled-components'
import config from '../../config'
import { getLoginUser } from '../../state/Auth/selectors'
import { startTimer } from '../../state/Clock/operations'
import { downloadCSV } from '../../state/DeviceById/operations'
import { getConcreteMonitorDevices } from '../../state/DeviceById/selectors'
import {
  loadDevices,
  syncUserData,
  useSessionList,
} from '../../state/Firebase/operations'
import { Device, Session } from '../../types'
import NavBar from '../NavBarContainer'
import DeviceLogList from './DeviceLogList'
import SessionPreview from './SessionPreview'

const Style = styled.div`
  .between {
    display: flex;
    justify-content: space-between;
  }
  .main-paper {
    width: 100%;
    padding: 0 32px;
    display: grid;
    grid-template-columns: max-content auto;
    gap: 16px;
    &[data-mobile='true'] {
      display: block;
      padding: 0 4px;
    }
  }
`

type Props = {}

function takeSession(
  sessionList: { deviceId: string; sessions: Session[] }[],
  devices: Device[],
  id: string | null,
) {
  if (!id) return [null, undefined]
  const [deviceId, sessionId] = id.split('---')
  const device = devices.find(d => d.id === deviceId)

  const session = sessionList
    .find(ds => ds.deviceId === deviceId)
    ?.sessions.find(s => s.id === sessionId)

  if (!session) return [null, undefined]

  if (
    device &&
    !session.threasholds &&
    `${device.currentSessionId}` === sessionId
  ) {
    session.threasholds = device.info.threasholds
  }
  return [session, device] as const
}

function DownloadPage(props: Props) {
  const dispatch = useDispatch()
  const devices = useSelector(getConcreteMonitorDevices)
  const [selectedId, setSelectedid] = React.useState<string | null>(null)
  const { breakpoint } = useBreakpoints()

  const isMobile = ['xs', 'sm'].includes(breakpoint)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const user = useSelector(getLoginUser)
  const sessionList = useSessionList(user)

  React.useEffect(() => {
    dispatch(loadDevices())
    dispatch(syncUserData())
    dispatch(startTimer())

    // eslint-disable-next-line
  }, [])
  const [selectedSession, selectedDevice] = React.useMemo(
    () => takeSession(sessionList, devices, selectedId),
    [sessionList, devices, selectedId],
  )

  if (!user.allowDownload) {
    return <Redirect to="/" />
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  if (isMobile) {
    return (
      <Style>
        <NavBar />
        <Grid container justify={'center'}>
          <Grid item xs={12} sm={12} md={12}>
            <div className="between" style={{ padding: '12px' }}>
              <div>
                <Typography variant={'h5'}>データダウンロード</Typography>
              </div>
            </div>

            <Paper className="main-paper" data-mobile={isMobile}>
              <div>
                <RadioGroup
                  defaultValue="top"
                  onChange={e => setSelectedid(e.target.value)}
                >
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                  >
                    {sessionList.map(({ sessions, deviceId }) => (
                      <DeviceLogList
                        key={deviceId}
                        deviceId={deviceId}
                        sessions={sessions}
                        current={
                          devices.find(d => d.id === deviceId)?.currentSessionId
                        }
                      />
                    ))}
                  </Popover>

                  <div className="between">
                    <div style={{ display: 'flex' }}>
                      <IconButton aria-describedby={id} onClick={handleClick}>
                        <MoreVertIcon />
                      </IconButton>
                      {(!selectedId || !selectedSession) && (
                        <Typography style={{ color: 'red', paddingTop: '8px' }}>
                          ログを選択してください
                        </Typography>
                      )}
                    </div>
                    <div style={{ marginTop: '-12px' }}>
                      {selectedId && selectedSession && (
                        <Button
                          color="primary"
                          onClick={() => {
                            downloadCSV(user, selectedSession, selectedId)
                          }}
                        >
                          ダウンロード
                        </Button>
                      )}
                      <Button>
                        <a
                          href="/"
                          style={{ textDecoration: 'none', color: 'black' }}
                        >
                          戻る
                        </a>
                      </Button>
                    </div>
                  </div>
                </RadioGroup>
              </div>
              <div>
                <Typography variant="h6" style={{ marginLeft: '1rem' }}>
                  ◆{user.title || '現場'}
                </Typography>
                {selectedDevice && (
                  <div>
                    <Typography variant="h6">{selectedDevice.id}</Typography>
                    {selectedDevice.info.label && (
                      <Typography variant="h6">
                        ({selectedDevice.info.label})
                      </Typography>
                    )}
                  </div>
                )}
                {selectedId && selectedSession && selectedDevice && (
                  <SessionPreview
                    deviceId={selectedDevice.id}
                    session={selectedSession}
                    sessionPath={selectedId}
                  />
                )}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Style>
    )
  }

  return (
    <Style>
      <NavBar />
      <Grid container justify={'center'}>
        <Grid item xs={12} sm={12} md={12}>
          <div className="between" style={{ padding: '12px' }}>
            <div>
              <Typography variant={'h5'}>データダウンロード</Typography>
              <Typography style={{ display: 'block' }}>
                ※ダウンロードデータをPC上で可視化するソフトは
                <a href={config.downloadSoftLink}>こちら</a>
              </Typography>
            </div>
            <div style={{ marginTop: '-12px' }}>
              {selectedId && selectedSession && (
                <Button
                  color="primary"
                  onClick={() => {
                    downloadCSV(user, selectedSession, selectedId)
                  }}
                >
                  ダウンロード
                </Button>
              )}
              <Button>
                <a href="/" style={{ textDecoration: 'none', color: 'black' }}>
                  戻る
                </a>
              </Button>
            </div>
          </div>
          <Typography variant="h6" style={{ marginLeft: '1rem' }}>
            ◆{user.title || '現場'}
          </Typography>
          <Paper className="main-paper">
            <div>
              <RadioGroup
                defaultValue="top"
                onChange={e => {
                  setSelectedid(e.target.value)
                }}
              >
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  {sessionList.map(({ sessions, deviceId }) => (
                    <DeviceLogList
                      key={deviceId}
                      deviceId={deviceId}
                      sessions={sessions}
                      current={
                        devices.find(d => d.id === deviceId)?.currentSessionId
                      }
                    />
                  ))}
                </Popover>

                <div style={{ display: 'flex' }}>
                  {(!selectedId || !selectedSession) && (
                    <Typography style={{ color: 'red', paddingTop: '8px' }}>
                      ログを選択してください
                    </Typography>
                  )}
                </div>
                {sessionList.map(({ sessions, deviceId }) => (
                  <DeviceLogList
                    key={deviceId}
                    deviceId={deviceId}
                    sessions={sessions}
                    current={
                      devices.find(d => d.id === deviceId)?.currentSessionId
                    }
                  />
                ))}
              </RadioGroup>
            </div>
            <div>
              {selectedId && selectedSession && selectedDevice && (
                <SessionPreview
                  deviceId={selectedDevice.id}
                  session={selectedSession}
                  sessionPath={selectedId}
                />
              )}
            </div>
          </Paper>
        </Grid>
      </Grid>
    </Style>
  )
}

export default DownloadPage
