import * as React from 'react'
import { connect } from 'react-redux'

import { refInit } from '../../state/Firebase/operations'
import { State } from '../../types'

type Props = {
  refInit: () => void
}

class Init extends React.Component<Props> {
  componentDidMount() {
    this.props.refInit()
  }

  render() {
    return null
  }
}

const ms = (state: State) => ({})
const conn = connect(
  ms,
  { refInit },
)

export default conn(Init)
