import { combineReducers } from 'redux'
import AdminById from './state/AdminById/reducer'
import Auth from './state/Auth/reducer'
import Clock from './state/Clock/reducer'
import DeviceById from './state/DeviceById/reducer'
import DeviceLogsById from './state/DeviceLogsById/reducer'
import LogCountById from './state/LogCountById/reducer'
import LoginForm from './state/LoginForm/reducer'
import SystemCache from './state/SystemCache/reducer'
import UserById from './state/UserById/reducer'
import { State } from './types'

export default combineReducers<State>({
  Auth,
  Clock,
  DeviceById,
  DeviceLogsById,
  LogCountById,
  LoginForm,
  UserById,
  AdminById,
  SystemCache,
})
