import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@material-ui/core'
import { range } from 'lodash'
import React from 'react'
import { useGraphConfig } from './useViewConfig'

const toLocalDateStr = (ts: number) =>
  new Date(ts + 9 * 60 * 60 * 1000).toISOString().substring(0, 16)

function GraphConfigForm() {
  const [config, setConfig] = useGraphConfig()

  console.log({ end: config.end })
  const updownError = config.down >= config.up
  const timeError = config.start >= config.end

  return (
    <div style={{ padding: '12px' }}>
      <Typography variant="h4">グラフ設定</Typography>

      <div style={{ display: 'grid' }}>
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <FormControl component="fieldset">
            <FormLabel component="legend">表示データ選択</FormLabel>
            <RadioGroup
              value={config.view}
              onChange={e =>
                setConfig({
                  ...config,
                  view: e.target.value as 'temp' | 'stre',
                })
              }
              style={{ display: 'flex' }}
            >
              <FormControlLabel
                value="stre"
                style={{ width: 'auto' }}
                control={<Radio />}
                label="強度(N/mm2)"
              />
              <FormControlLabel
                value="temp"
                style={{ width: 'auto' }}
                control={<Radio />}
                label="温度(℃)"
              />
            </RadioGroup>
          </FormControl>
        </div>

        <FormControl component="fieldset">
          <FormLabel component="legend">表示CH設定</FormLabel>
          <div style={{ display: 'flex', flexWrap: 'wrap' }}>
            {range(1, 12).map(i => (
              <div key={i}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!config.hideCh[i]}
                      onChange={e =>
                        setConfig({
                          ...config,
                          hideCh: { ...config.hideCh, [i]: !e.target.checked },
                        })
                      }
                    />
                  }
                  label={`CH${i}`}
                />
              </div>
            ))}
          </div>
        </FormControl>
        <FormControl component="fieldset">
          <FormLabel component="legend">縦軸</FormLabel>
          <div style={{ display: 'flex' }}>
            <TextField
              name="label"
              error={updownError}
              helperText={updownError && '上限値が下限値より小さい'}
              label="下限"
              type="number"
              value={config.down}
              onChange={e => {
                setConfig({ ...config, down: Number(e.target.value) })
              }}
            />
            <TextField
              name="label"
              error={updownError}
              helperText={updownError && '上限値が下限値より小さい'}
              label="上限"
              type="number"
              value={config.up}
              onChange={e => {
                setConfig({ ...config, up: Number(e.target.value) })
              }}
            />
          </div>
        </FormControl>
        <FormControl component="fieldset">
          <FormLabel component="legend">横軸</FormLabel>
          <div style={{ display: 'flex' }}>
            <TextField
              type="datetime-local"
              name="label"
              InputLabelProps={{ shrink: true }}
              error={timeError}
              helperText={timeError && '開始が終了より前'}
              label="開始"
              value={toLocalDateStr(config.start)}
              onChange={e => {
                const start = +new Date(e.target.value)

                if (Number.isNaN(start)) return
                setConfig({ ...config, start })
              }}
            />
            <TextField
              type="datetime-local"
              name="label"
              InputLabelProps={{ shrink: true }}
              error={timeError}
              helperText={timeError && '開始が終了より前'}
              label="終了"
              value={toLocalDateStr(config.end)}
              onChange={e => {
                const end = +new Date(e.target.value)

                if (Number.isNaN(end)) return

                setConfig({ ...config, end })
              }}
            />
          </div>
        </FormControl>
      </div>
    </div>
  )
}

export default GraphConfigForm
