import { useSessionStorage } from 'react-use'
import { Session, Cache } from './../types/index'

export const sessionKeyStart = 'session-start'
export const useSessionTimes = () =>
  useSessionStorage<Cache>(sessionKeyStart, {})

export const sessionKeyList = 'session-list'
export const useSessions = () =>
  useSessionStorage<{ deviceId: string; sessions: Session[] }[]>(
    sessionKeyList,
    [],
  )
