import _ from 'lodash'
import moment from 'moment'
import {
  ConcreteThreashold,
  ConcreteThreasholdById,
  Device,
  LogRaw,
  Session,
  ThunkAction,
  User,
} from '../../types'
import { download } from '../../utils'
// import { loadLogs } from '../Firebase/operations'
import { loadSessionLogs } from '../Firebase/operations'
import * as actions from './actions'
import * as selectors from './selectors'

const calcDevice = (device: Device, devicePre?: Device): Device => {
  if (!devicePre) {
    return device
  }

  return {
    ...device,
    lastLogs: device.lastLogs,
  }
}

export function saveDevice(device: Device): ThunkAction {
  return async (dispatch, getState) => {
    const preDevice = selectors.getDevice(getState(), device.id)

    dispatch(actions.receiveDevice(calcDevice(device, preDevice)))
  }
}

type Th = NonNullable<ConcreteThreashold>

export function makeCSV(
  logs: LogRaw[],
  threasholds: ConcreteThreasholdById,
): string {
  const th: { [id: string]: Th } = {}

  _.range(1, 12).forEach(i => {
    const thRaw = threasholds[i]

    if (thRaw === undefined) throw Error('Can\t make CSV, breaked threashold')
    th[i] = thRaw
  })

  const nums: number[][] = []

  nums.push(_.range(1, 12).map(i => th[i]?.resistance.levelA ?? -999))
  nums.push(_.range(1, 12).map(i => th[i]?.resistance.levelB ?? -999))
  nums.push(_.range(1, 12).map(i => th[i]?.resistance.levelC ?? -999))
  nums.push(_.range(1, 12).map(i => th[i]?.voltage.levelA ?? -999))
  nums.push(_.range(1, 12).map(i => th[i]?.time.levelA ?? -999))
  nums.push(_.range(1, 12).map(i => th[i]?.time.levelB ?? -999))
  const lines = nums.map(n => n.map(String).join(','))

  console.log(logs)

  logs.forEach(log => {
    const time = log.timestamp ? moment(log.timestamp) : null
    const vals: number[] = []

    _.range(1, 12).forEach(i => {
      if (!log.fields.sensors[i])
        throw new Error('Can\t make CSV, breaked data')
      vals.push(log.fields.sensors[i].resistanceValue || 0)
      vals.push(log.fields.sensors[i].compactionTime || 0)
    })
    const cols = [
      time?.format('YYYY/MM/DD') || '',
      time?.format('HH:mm:ss') || '',
      ...vals,
    ]

    lines.push(cols.map(String).join(','))
  })

  return lines.join('\r\n')
}

export async function downloadCSV(
  user: User,
  session: Session,
  sessionPath: string,
) {
  const logs = await loadSessionLogs(user.id, sessionPath)

  const { threasholds } = session

  if (!logs || !threasholds) {
    alert('ログがありません')
    return
  }

  // v1 (管理No)_YYMMDD（項番2桁).csv　※ファイル例：MUDR100_001_19120201.csv
  // v2 端末名_yyyymmddhhmiss.csv

  // console.log(session)

  const filename =
    session.filename ||
    `${sessionPath.split('---')[0]}_${moment(session.createdAt * 1000).format(
      'YYYYMMDD_HHmmss',
    )}`

  download(makeCSV(logs, threasholds), filename)
}
