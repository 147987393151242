import React from 'react'
import { Redirect, Route, Router, Switch } from 'react-router-dom'
import * as history from 'history'
import Init from '../Auth/Init'
import ConcreteMonitorAppPage from '../ConcreteMonitorAppPage'
import DownloadPage from '../DownloadPage'
import LoginPage from '../LoginPage'

import AuthContainer from '../Auth'

export const browserHistory = history.createBrowserHistory()

class App extends React.Component {
  render() {
    return (
      <Router history={browserHistory}>
        <div>
          <Init />
          <Switch>
            <Route exact path="/login" component={LoginPage} />
            <AuthContainer
              redirectPath="/login"
              userRoute={
                <div>
                  <Switch>
                    <Route
                      exact
                      path={'/'}
                      component={ConcreteMonitorAppPage}
                    />
                    <Route exact path={'/download'} component={DownloadPage} />
                    <Route component={() => <Redirect to="/" />} />
                  </Switch>
                </div>
              }
            />
          </Switch>
        </div>
      </Router>
    )
  }
}

export default App
