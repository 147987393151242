import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { SystemCache } from '../../types'

import * as actions from './actions'

export type State = SystemCache

const initialState: State = {
  toggles: {
    configItgLog: true,
    configSurfaceTemp: true,
  },
  editDevice: {
    editing: false,
    deviceId: '',
  },
  createDevice: {
    editing: false,
  },
  editUser: {
    editing: false,
    userId: '',
  },
  createUser: {
    editing: false,
  },
  showName: true,
}

export default reducerWithInitialState<State>(initialState)
  .case(actions.updateSystemCache, (state, systemCache) => {
    return { ...state, ...systemCache }
  })
  .case(actions.updateSystemCacheToggles, (state, toggles) => {
    return { ...state, toggles: { ...state.toggles, ...toggles } }
  })
  .case(actions.toggleSystemCache, (state, key) => {
    return {
      ...state,
      toggles: { ...state.toggles, [key]: !state.toggles[key] || false },
    }
  })
  .case(actions.editDeviceStart, (state, deviceId) => {
    return { ...state, editDevice: { editing: true, deviceId } }
  })
  .case(actions.editDeviceEnd, state => {
    return { ...state, editDevice: { editing: false, deviceId: '' } }
  })
  .case(actions.createDeviceStart, state => {
    return { ...state, createDevice: { editing: true } }
  })
  .case(actions.createDeviceEnd, state => {
    return { ...state, createDevice: { editing: false } }
  })
  .case(actions.editUserStart, (state, userId) => {
    return { ...state, editUser: { editing: true, userId } }
  })
  .case(actions.editUserEnd, state => {
    return { ...state, editUser: { editing: false, userId: '' } }
  })
  .case(actions.createUserStart, state => {
    return { ...state, createUser: { editing: true } }
  })
  .case(actions.createUserEnd, state => {
    return { ...state, createUser: { editing: false } }
  })
  .case(actions.setShowName, (state, showName) => {
    return { ...state, showName }
  })
  .case(actions.clearSystemCache, () => {
    return initialState
  })
