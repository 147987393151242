import { reducerWithInitialState } from 'typescript-fsa-reducers'
import { DayLogs } from '../../types'
import * as actions from './actions'

export type State = { [id: string]: DayLogs }
export const initialState: State = {} as State

export default reducerWithInitialState(initialState).case(
  actions.receiveLogs,
  (state, dayLogs) => {
    return {
      ...state,
      [dayLogs.id]: dayLogs,
    }
  },
)
