import React from 'react'

import { Typography } from '@material-ui/core'

import LinkageForm from './LinkageForm'

type Props = {}

function LinkageConfigForm(props: Props) {
  return (
    <div style={{ padding: '12px' }}>
      <Typography variant="h4">データ連携設定</Typography>
      <LinkageForm />
    </div>
  )
}

export default LinkageConfigForm
