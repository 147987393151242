import { Typography } from '@material-ui/core'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import styled from 'styled-components'
import { Content } from './LevelBox'

type Props = {
  title: string
  color: string
  labels: string[]
}

export function timeLabel(ts: number | undefined): string {
  if (!ts) return ''
  return moment(ts).format('HH:mm:ss')
}

const CompTimeHeader = ({ labels, title, color }: Props) => {
  return (
    <Style>
      <div className="cell">
        <Typography>{title}</Typography>
        <Typography></Typography>
      </div>
      <div className="times">
        {_.range(1, 12).map(i => {
          if (!labels[i]) return <div key={i} className="cell" />

          return (
            <div key={i} className="cell">
              <Typography style={{ color }}>{labels[i]}</Typography>
            </div>
          )
        })}
      </div>
    </Style>
  )
}

const Style = styled(Content)`
  .cell {
    overflow: hidden;
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    justify-content: space-evenly;
  }
  p {
    text-align: center;
  }
  .times {
    display: grid;
    grid-template-columns: repeat(11, 1fr);
  }
`

export default CompTimeHeader
