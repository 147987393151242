import moment from 'moment'
import { ThunkAction } from '../../types'

import config from '../../config'
import { updateClock } from './actions'

export function startTimer(): ThunkAction {
  return dispatch => {
    setInterval(() => {
      dispatch(updateTimer())
    }, config.clockIntervalMs)
  }
}

export function updateTimer(): ThunkAction {
  return (dispatch, getState) => {
    const timestamp = Date.now()
    const m = moment(timestamp)
    const timestampStr = m.format('YYYY/MM/DD HH:mm:ss')

    dispatch(
      updateClock({
        timestamp,
        timestampStr,
      }),
    )
  }
}
