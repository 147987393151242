import { Button, TextField } from '@material-ui/core'
import { useFormik } from 'formik'
import React from 'react'
import { connect } from 'react-redux'
import { getLoginUser } from '../../../state/Auth/selectors'
import { updateUserTitle } from '../../../state/Firebase/operations'
import { State, User } from '../../../types'

type Props = {
  user: User
  updateUserTitle: (userId: string, title: string) => void
}

type Fields = {
  title: string
}

function ConcreteTopicForm(props: Props) {
  const { user } = props

  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    setFieldTouched,
  } = useFormik<Fields>({
    initialValues: { title: user.title },
    validate: values => {
      const errors: Partial<Fields> = {}

      if (!values.title) {
        errors.title = 'Required'
      }
      return errors
    },
    onSubmit: (values: Fields, { setSubmitting, setErrors }) => {
      props.updateUserTitle(user.id, values.title)
    },
  })
  const change = (title, e) => {
    e.persist()
    handleChange(e)
    setFieldTouched(title, true, false)
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr max-content',
          }}
        >
          <TextField
            name="title"
            helperText={touched.title ? errors.title : ''}
            error={touched.title && Boolean(errors.title)}
            label="現場名"
            value={values.title}
            onChange={change.bind(null, 'name')}
            fullWidth
          />
          <Button
            disabled={!!errors.title || values.title === user.title}
            onClick={e => handleSubmit()}
          >
            変更する
          </Button>
        </div>
      </form>
    </div>
  )
}

type OProps = {}

const conn = connect(
  (state: State, op: OProps) => ({
    user: getLoginUser(state),
  }),
  {
    updateUserTitle,
  },
)

export default conn(ConcreteTopicForm)
