import { Button, Modal, Typography } from '@material-ui/core'
import React from 'react'
import { ModalCardSmall } from '../../components'
import config from '../../config'
import ConcreteConfigForm from './ConcreteConfigForm'

type Props = {
  modalOpen: boolean
  handleConcreteModalClose: () => void
}

function ConcreteConfigModal(props: Props) {
  return (
    <Modal open={props.modalOpen} onClose={props.handleConcreteModalClose}>
      <ModalCardSmall>
        <ConcreteConfigForm />
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button onClick={props.handleConcreteModalClose}>閉じる</Button>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: '5px',
          }}
        >
          <Typography style={{ color: '#333' }}>
            ビルドバージョン: {config.gitrev}
          </Typography>
        </div>
      </ModalCardSmall>
    </Modal>
  )
}

export default ConcreteConfigModal
