import styled from 'styled-components'

export const Content = styled.div`
  display: grid;
  grid-template-columns: 2fr 10fr;
  height: 100%;
`

export const ColTitleBox = styled.div`
  display: grid;
  height: 100%;
  grid-template-rows: 8fr 8fr 8fr 6fr; /* 80% 20% */
`

export const ColBox = styled.div`
  width: calc(100% / 11);
  height: 100%;
`
export const LevelBoxLabel = styled.div`
  /* border: solid 1px black; */
  border: solid 1px black;
  display: flex;
  align-items: center;
  justify-content: center;
`

export default styled.div`
  border: solid 1px aqua;
  margin: 2px;
  background: black;
  &[data-level='0'] {
    border-color: gray;
    background: gray;
  }
  &[data-level='1'] {
    &:nth-of-type(3) {
      border-color: red;
      background: red;
    }
  }
  &[data-level='2'] {
    &:nth-of-type(3) {
      background: aqua;
    }
  }
  &[data-level='3'] {
    &:nth-of-type(2) {
      background: aqua;
    }
    &:nth-of-type(3) {
      background: aqua;
    }
  }
  &[data-level='4'] {
    background: aqua;
  }
  &[data-level='5'] {
    border-color: yellow;
    background: yellow;
  }
  &[data-level='6'] {
    border-color: green;
    background: green;
  }
`
