import { Typography } from '@material-ui/core'
import React from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { getShowName } from '../../state/SystemCache/selectors'
import { Device } from '../../types'

const Label = styled(Typography)`
  &[data-col='4'] {
    @media screen and (max-width: 800px) {
      font-size: 10px !important;
    }
  }
`

type Props = { device: Device; col: number }
function ConcreteDeviceHeader({ device, col }: Props) {
  const showName = useSelector(getShowName)

  return (
    <div
      style={{
        overflow: 'hidden',
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr max-content',
          alignContent: 'end',
        }}
      >
        <Label variant="h6" color="inherit" data-col={col}>
          {device.id}
        </Label>

        <Label
          variant="subtitle1"
          gutterBottom
          style={{ color: device.info.keeped ? 'green' : 'red' }}
          data-col={col}
        >
          {device.info.keepedStr}
        </Label>
      </div>
      {showName && (
        <Label
          variant="h6"
          color="inherit"
          data-col={col}
          style={{
            fontSize: col === 1 ? '1.25rem' : `calc((100vw - 32px) / 4 / 20)`,
            overflow: 'hidden',
            height: col === 1 ? '1.8rem' : `calc((180vw - 32px) / 4 / 20)`,
            width: '100%',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {device.info.label}
        </Label>
      )}
    </div>
  )
}
export default ConcreteDeviceHeader
